import React, { useState, useRef } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Table, Alert, Modal, Form, Button, InputGroup } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { API } from "../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Home1() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    let passwordErrorMsg = [];

    const [statePasswordModalShow, setStatePasswordModalShow] = useState(false);
    const [statePasswordErrorShow, setStatePasswordErrorShow] = useState(false);
    const [statePasswordErrorMsg, setStatePasswordErrorMsg] = useState("");

    const [stateOldPasswordEye, setStateOldPasswordEye] = useState(false);
    const [stateNewPassword1Eye, setStateNewPassword1Eye] = useState(false);
    const [stateNewPassword2Eye, setStateNewPassword2Eye] = useState(false);

    const oldPasswordInputRef = useRef();
    const newPasswordInput1Ref = useRef();
    const newPasswordInput2Ref = useRef();

    function handleChangePasswordClick(e) {
        e.preventDefault();

        setStatePasswordModalShow(true);
    }

    function handlePasswordModalHide(e) {
        setStatePasswordModalShow(false);
        setStatePasswordErrorShow(false);
    }

    function handleChangePassword(e) {
        e.preventDefault();

        let md5 = require("md5");

        let oldPassword = oldPasswordInputRef.current.value;
        let newPassword1 = newPasswordInput1Ref.current.value;
        let newPassword2 = newPasswordInput2Ref.current.value;

        if (md5(oldPassword) === reduxState.userPassword &&
            newPassword1.length >= 8 && newPassword2.length >= 8 &&
            newPassword1 === newPassword2) {
            const msg = {
                command: "UPDATE_PASSWORD",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: md5(newPassword1)
            };

            const json = JSON.stringify(msg);

            //console.log(json);

            api.post("home.php", Buffer.from(json).toString("base64"))
                .then(res => {
                    const data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    if (data[0] !== "fail") {
                        Cookies.set("user-id", reduxState.userID, { expires: 1 });
                        Cookies.set("user-password", md5(newPassword1), { expires: 1 });

                        const userID = reduxState.userID;
                        const userPrefix = reduxState.userPrefix;
                        const userFirstName = reduxState.userFirstName;
                        const userLastName = reduxState.userLastName;
                        const userEmail = reduxState.userEmail;
                        const userType = reduxState.userType;
                        const userState = reduxState.userState;

                        {
                            const reduxState = {
                                userID: userID,
                                userPassword: md5(newPassword1),
                                userPrefix: userPrefix,
                                userFirstName: userFirstName,
                                userLastName: userLastName,
                                userEmail: userEmail,
                                userType: userType,
                                userState: userState
                            }

                            reduxDispatch({ type: "UPDATE", reduxState });
                        }

                        // console.log("pass");

                        setStatePasswordModalShow(false);
                        setStatePasswordErrorShow(false);
                    } else {
                        passwordErrorMsg = [];
                        setStatePasswordErrorShow(true);

                        passwordErrorMsg.push("การเปลี่ยนรหัสผ่านผิดพลาด");

                        setStatePasswordErrorMsg(passwordErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                })
                .catch(err => {
                    passwordErrorMsg = [];
                    setStatePasswordErrorShow(true);

                    passwordErrorMsg.push("เครือข่ายผิดพลาด");

                    setStatePasswordErrorMsg(passwordErrorMsg.map(function (object, index) {
                        return <div key={index}>{`- `}{object}</div>;
                    }));
                });
        } else {
            passwordErrorMsg = [];
            setStatePasswordErrorShow(true);

            if (md5(oldPassword) !== reduxState.userPassword) {
                passwordErrorMsg.push("กรอกรหัสผ่านเก่าไม่ถูกต้อง");
            }

            if (newPassword1.length < 8 || newPassword2.length < 8) {
                passwordErrorMsg.push("รหัสผ่านใหม่จะต้องมีอย่างน้อย 8 ตัว");
            }

            if (newPassword1 !== newPassword2) {
                passwordErrorMsg.push("รหัสผ่านใหม่ 1 และ 2 ไม่ตรงกัน");
            }

            setStatePasswordErrorMsg(passwordErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    let contents = null;

    if (reduxState.userID === "") {
        contents =
            <Container fluid>
            </Container>;
    } else {
        contents =
            <Container fluid>
                <Row>
                    <Col align="left">
                        <br />
                        <Card bg="light" text="#000000">
                            <Card.Header style={{ fontColor: "#000000" }}>
                                <b>หน้าแรก</b>
                            </Card.Header>
                            <Card.Body>
                                <Button disabled={reduxState.userType === "ผู้สังเกต" ? true : false} variant="success" size="md" onClick={handleChangePasswordClick} text="#FFFFFF">เปลี่ยนรหัสผ่าน</Button><br /><br />
                                <Table striped bordered size="sm" responsive="sm" >
                                    <thead>
                                        <tr>
                                            <td colSpan="2"><div align="center"><b>ข้อมูลผู้ใช้</b></div></td>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td style={{ whiteSpace: "nowrap" }}>เลขประจำตัวประชาชน</td>
                                            <td><div align="left">{reduxState.userID}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">คำนำหน้า</div></td>
                                            <td><div align="left">{reduxState.userPrefix}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">ชื่อ</div></td>
                                            <td><div align="left">{reduxState.userFirstName}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">นามสกุล</div></td>
                                            <td><div align="left">{reduxState.userLastName}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">อีเมล์</div></td>
                                            <td><div align="left">{reduxState.userEmail}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">ประเภท</div></td>
                                            <td><div align="left">{reduxState.userType}</div></td>
                                        </tr>
                                        <tr>
                                            <td><div align="left">สถานะ</div></td>
                                            <td><div align="left">{reduxState.userState}</div></td>
                                        </tr>
                                    </tbody>
                                </Table>
                            </Card.Body>
                        </Card><br />
                    </Col>
                </Row>
                <Modal show={statePasswordModalShow} onHide={handlePasswordModalHide} backdrop="static" keyboard={false}
                    style={{ fontFamily: "Sarabun" }} centered size="md">
                    <Modal.Header closeButton>
                        <Modal.Title>เปลี่ยนรหัสผ่าน</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table borderless={true} size="sm" responsive="sm">
                            <tbody>
                                <tr>
                                    <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <Alert variant="danger" show={statePasswordErrorShow}>{statePasswordErrorMsg}</Alert>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <InputGroup>
                                            <Form.Control type={stateOldPasswordEye ? `input` : `password`} ref={oldPasswordInputRef} placeholder="รหัสผ่านเก่า" />
                                            <Button variant="light" onClick={() => setStateOldPasswordEye(prev => ~prev)}>
                                                {stateOldPasswordEye ? <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>}
                                            </Button>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <InputGroup>
                                            <Form.Control type={stateNewPassword1Eye ? `input` : `password`} ref={newPasswordInput1Ref} placeholder="รหัสผ่านใหม่ 1" /><br />
                                            <Button variant="light" onClick={() => setStateNewPassword1Eye(prev => ~prev)}>
                                                {stateNewPassword1Eye ? <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>}
                                            </Button>
                                        </InputGroup>
                                    </td>
                                </tr>
                                <tr>
                                    <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                        <InputGroup>
                                            <Form.Control type={stateNewPassword2Eye ? `input` : `password`} ref={newPasswordInput2Ref} placeholder="รหัสผ่านใหม่ 2" />
                                            <Button variant="light" onClick={() => setStateNewPassword2Eye(prev => ~prev)}>
                                                {stateNewPassword2Eye ? <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>}
                                            </Button>
                                        </InputGroup>
                                    </td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="success" onClick={handleChangePassword} size="md">
                            ตกลง
                        </Button>
                    </Modal.Footer>
                </Modal>
            </Container>;
    }

    return (
        contents
    );
}

export default Home1;