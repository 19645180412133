import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Card, Container, Col, Row, Table, Modal, Alert } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin98() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    let addErrorMsg = [];

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateStd3Table, setStateStd3Table] = useState(null);

    const [stateAddModalShow, setStateAddModalShow] = useState(false);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState("");
    const [stateAddStd3, setStateAddStd3] = useState("");

    const [stateOfferModalShow, setStateOfferModalShow] = useState(false);

    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteStd3Id, setStateDeleteStd3Id] = useState(null);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: data[0].edu_years_id,
                            edu_term: data[0].edu_term
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = parseInt(a.id);
                                    let y1 = parseInt(b.id);

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateStd3Table(data);
                            } else {
                                setStateStd3Table(null);
                            }
                        });
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddStd3("");
        setStateAddModalShow(true);
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handleAddStd3Change(e) {
        setStateAddStd3(e.target.value);
    }

    function handleAdd(e) {
        e.preventDefault();

        if (stateAddStd3 !== "") {
            const msg = {
                command: "INSERT",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    std3: stateAddStd3,
                    edu_year_id: stateSelectedEduYearId,
                    edu_term: stateSelectedEduTerm
                }
            };

            //console.log(msg);

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: {
                                edu_year_id: stateSelectedEduYearId,
                                edu_term: stateSelectedEduTerm
                            }
                        };

                        const json = JSON.stringify(msg);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                //console.log(data);

                                if (data[0] !== "fail") {
                                    data.sort(function (a, b) {
                                        let x1 = parseInt(a.id);
                                        let y1 = parseInt(b.id);

                                        if (x1 < y1) {
                                            return -1;
                                        }

                                        if (x1 > y1) {
                                            return 1;
                                        }

                                        return 0;
                                    });

                                    setStateStd3Table(data);
                                    setStateAddModalShow(false);
                                } else {
                                    setStateStd3Table(null);
                                    setStateAddModalShow(false);
                                }
                            });

                        setStateAddErrorShow(false);
                    }
                    else {
                        addErrorMsg = [];
                        setStateAddErrorShow(true);

                        if (stateAddStd3 === "") {
                            addErrorMsg.push("การเพิ่มมาตรฐานสถานศึกษามีความผิดปกติ");
                        }

                        setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            addErrorMsg = [];
            setStateAddErrorShow(true);

            if (stateAddStd3 === "") {
                addErrorMsg.push("กรุณากรอกมาตรฐานสถานศึกษา");
            }

            setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleOfferClick(e) {
        e.preventDefault();

        setStateOfferModalShow(true);
    }

    function handleOffer(e) {
        e.preventDefault();

        const msg = {
            command: "",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_offer_teacher_standard.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);
                if(data[0] === "pass") {
                    setStateOfferModalShow(false);
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteStd3Id(e.target.getAttribute("id"));
        setStateDeleteModalShow(true);
    }

    function handleDelete(e) {
        e.preventDefault();

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateDeleteStd3Id
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.id.toLowerCase();
                                    let y1 = b.id.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateStd3Table(data);
                                setStateDeleteModalShow(false);
                            } else {
                                setStateStd3Table(null);
                                setStateDeleteModalShow(false);
                            }
                        });

                    setStateAddErrorShow(false);
                }
            });
    }
    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEdit(e) {
        e.preventDefault();

        //console.log(e.target.getAttribute("id"));
    }

    let std3TableData = null;

    if (stateStd3Table) {
        std3TableData = stateStd3Table.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center">{index + 1}</div></td>
                <td><div align="left">{object.name}</div></td>
                <td><div align="center"><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center"><Button id={object.id} variant="success" size="sm" onClick={handleEdit} text="#FFFFFF">แก้ไข</Button></div></td>
            </tr>
        });
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>เพิ่ม/ลบ/แก้ไข มาตรฐานที่ 3 กระบวนการจัดการเรียนการสอนที่เน้นผู้เรียนเป็นสำคัญ</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่ม</Button>&nbsp;&nbsp;
                    <Button variant="warning" size="md" onClick={handleOfferClick} text="#FFFFFF">มอบหมายให้ครู</Button>
                    <br />
                    <br />
                    <div>
                        <Table striped bordered size="sm" responsive="xxl">
                            <thead>
                                <tr>
                                    <th><div align="center">ที่</div></th>
                                    <th><div align="center">มาตรฐานสถานศึกษา</div></th>
                                    <th><div align="center"></div></th>
                                    <th><div align="center"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {std3TableData}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกมาตรฐานสถานศึกษา</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control as="textarea" value={stateAddStd3} onChange={handleAddStd3Change} placeholder="มาตรฐานสถานศึกษา" rows="3" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateOfferModalShow} onHide={() => setStateOfferModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการมอบหมายมาตรฐานสถานศึกษา</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="warning" onClick={handleOffer} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateOfferModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
{/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบมาตรฐานสถานศึกษา</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        </div>
    );
}

export default Admin98;