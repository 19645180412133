const initState = {
    userID: "",
    userPassword: "",
    userPrefix: "",
    userFirstName: "",
    userLastName: "",
    userEmail: "",
    userType: "",
    userState: "",
}

const Reducer = (state = initState, action) => {
    switch (action.type) {
        case "UPDATE":
            state = action.reduxState;
            return state;
        default:
            return state;
    }
}

export default Reducer;