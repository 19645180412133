import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Table, Button, Card } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin2() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState("1");
    const [stateEduYearsTable, setStateEduYearsTable] = useState(null);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let years = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (years[0] !== "fail") {
                    years.sort(function (a, b) {
                        let x1 = a.name.toLowerCase();
                        let y1 = b.name.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStateEduYearsTable(years);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                setStateSelectedEduYearId(data[0].edu_years_id);
                                setStateSelectedEduYear(data[0].edu_years_name);
                                setStateSelectedEduTerm(data[0].edu_term);
                            } else {
                                setStateSelectedEduYear(null);
                                setStateSelectedEduYearId(null);
                                setStateSelectedEduTerm(null);
                            }
                        });
                }
            });

        return () => {
        }
    }, []);

    function handleEduYearClick(e) {
        e.preventDefault();

        setStateSelectedEduYear(e.target.getAttribute("year"));
        setStateSelectedEduYearId(e.target.getAttribute("id"));
    }

    function handleEduTermClick(e) {
        e.preventDefault();

        setStateSelectedEduTerm(e.target.getAttribute("term"));
    }

    function handleConfirm(e) {
        e.preventDefault();

        const msg = {
            command: "INSERT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_years_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let years = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (years[0] !== "fail") {
                                years.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();
            
                                    if (x1 < y1) {
                                        return -1;
                                    }
            
                                    if (x1 > y1) {
                                        return 1;
                                    }
            
                                    return 0;
                                });

                                setStateEduYearsTable(years);

                                const msg = {
                                    command: "SELECT",
                                    id: reduxState.userID,
                                    password: reduxState.userPassword,
                                    data: ""
                                };

                                const json = JSON.stringify(msg);

                                const options = {
                                    header: {
                                        "content-type": "text/plain"
                                    }
                                };

                                api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
                                    .then(res => {
                                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                        if (data[0] !== "fail") {
                                            setStateSelectedEduYearId(data[0].edu_years_id);
                                            setStateSelectedEduYear(data[0].edu_years_name);
                                            setStateSelectedEduTerm(data[0].edu_term);
                                        } else {
                                            setStateSelectedEduYear(null);
                                            setStateSelectedEduYearId(null);
                                            setStateSelectedEduTerm(null);
                                        }
                                    });
                            }
                        });
                }
            });
    }

    let eduYearsDropdownData = null;

    if (stateEduYearsTable) {
        eduYearsDropdownData = stateEduYearsTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} year={object.name} onClick={handleEduYearClick}>{object.name}</Dropdown.Item>;
        });
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}><b>กำหนดปีการศึกษาและภาคเรียน</b></Card.Header>
                <Card.Body>
                    <Table>
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", borderLeft: "0px" }}>ปีการศึกษา :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedEduYear}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {eduYearsDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", borderLeft: "0px" }}>ภาคเรียน :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedEduTerm}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            <Dropdown.Item term={`1`} onClick={handleEduTermClick}>1</Dropdown.Item>
                                            <Dropdown.Item term={`2`} onClick={handleEduTermClick}>2</Dropdown.Item>
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Button variant="success" size="md" onClick={handleConfirm} text="#FFFFFF">ตกลง</Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Admin2;