import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Dropdown } from "react-bootstrap";
import Admin1 from "./admin/Admin1";
import Admin2 from "./admin/Admin2";
import Admin4 from "./admin/Admin4";
import Admin6 from "./admin/Admin6";
import Admin10 from "./admin/Admin10";
import Admin81 from "./admin/Admin81";
import Admin82 from "./admin/Admin82";
import Admin96 from "./admin/Admin96";
import Admin97 from "./admin/Admin97";
import Admin98 from "./admin/Admin98";
import Admin99 from "./admin/Admin99";

function Admin() {
    const [stateSelectedPage, setStateSelectedPage] = useState(null);

    function handleM1Click(e) {
        e.preventDefault();

        setStateSelectedPage("1");
    }

    function handleM2Click(e) {
        e.preventDefault();

        setStateSelectedPage("2");
    }

    function handleM4Click(e) {
        e.preventDefault();

        setStateSelectedPage("4");
    }

    function handleM6Click(e) {
        e.preventDefault();

        setStateSelectedPage("6");
    }

    function handleM10Click(e) {
        e.preventDefault();

        setStateSelectedPage("10");
    }

    function handleM81Click(e) {
        e.preventDefault();

        setStateSelectedPage("81");
    }

    function handleM82Click(e) {
        e.preventDefault();

        setStateSelectedPage("82");
    }

    function handleM96Click(e) {
        e.preventDefault();

        setStateSelectedPage("96");
    }

    function handleM97Click(e) {
        e.preventDefault();

        setStateSelectedPage("97");
    }

    function handleM98Click(e) {
        e.preventDefault();

        setStateSelectedPage("98");
    }

    function handleM99Click(e) {
        e.preventDefault();

        setStateSelectedPage("99");
    }

    let page = null;

    if (stateSelectedPage === "1") {
        page = <Admin1 />;
    } else if (stateSelectedPage === "2") {
        page = <Admin2 />;
    } else if (stateSelectedPage === "4") {
        page = <Admin4 />;
    } else if (stateSelectedPage === "6") {
        page = <Admin6 />;
    } else if (stateSelectedPage === "10") {
        page = <Admin10 />;
    } else if (stateSelectedPage === "81") {
        page = <Admin81 />;
    } else if (stateSelectedPage === "82") {
        page = <Admin82 />;
    } else if (stateSelectedPage === "96") {
        page = <Admin96 />;
    } else if (stateSelectedPage === "97") {
        page = <Admin97 />;
    } else if (stateSelectedPage === "98") {
        page = <Admin98 />;
    } else if (stateSelectedPage === "99") {
        page = <Admin99 />;
    }

    return (
        <Container fluid>
            <Row>
                <Col sm="auto">
                    <br />
                    <Dropdown>
                        <Dropdown.Toggle variant="dark" text="#000000">เมนู</Dropdown.Toggle>
                        <Dropdown.Menu variant="dark" text="#000000">
                            <Dropdown.Item href="#a1" onClick={handleM1Click}>เพิ่ม/ลบ/แก้ไข ปีการศึกษา</Dropdown.Item>
                            <Dropdown.Item href="#a2" onClick={handleM2Click}>กำหนดปีการศึกษาและภาคเรียน</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#a3">เพิ่ม/ลบ/แก้ไข คำนำหน้านักเรียน</Dropdown.Item>
                            <Dropdown.Item href="#a4" onClick={handleM4Click}>เพิ่ม/ลบ/แก้ไข นักเรียน</Dropdown.Item>
                            <Dropdown.Item href="#a5">เพิ่ม/ลบ/แก้ไข ห้องเรียน</Dropdown.Item>
                            <Dropdown.Item href="#a6" onClick={handleM6Click}>เพิ่ม/ลบ/แก้ไข นักเรียนในห้องเรียน</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#a7">เพิ่ม/ลบ/แก้ไข คำนำหน้าผู้ใช้</Dropdown.Item>
                            <Dropdown.Item href="#a8">เพิ่ม/ลบ/แก้ไข ประเภทผู้ใช้</Dropdown.Item>
                            <Dropdown.Item href="#a9">เพิ่ม/ลบ/แก้ไข สภานะผู้ใช้</Dropdown.Item>
                            <Dropdown.Item href="#a10" onClick={handleM10Click}>เพิ่ม/ลบ/แก้ไข ผู้ใช้</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#a81" onClick={handleM81Click}>เพิ่ม/ลบ/แก้ไข วิชาสอน</Dropdown.Item>
                            <Dropdown.Item href="#a82" onClick={handleM82Click}>เพิ่ม/ลบ/แก้ไข ภาระงานสอน</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#a96" onClick={handleM96Click}>เพิ่ม/ลบ/แก้ไข มาตรฐานที่ 1</Dropdown.Item>
                            <Dropdown.Item href="#a97" onClick={handleM97Click}>เพิ่ม/ลบ/แก้ไข มาตรฐานที่ 2</Dropdown.Item>
                            <Dropdown.Item href="#a98" onClick={handleM98Click}>เพิ่ม/ลบ/แก้ไข มาตรฐานที่ 3</Dropdown.Item>
                            <Dropdown.Divider />
                            <Dropdown.Item href="#a99" onClick={handleM99Click}>สร้างฐานข้อมูลเริ่มต้น</Dropdown.Item>
                        </Dropdown.Menu>
                    </Dropdown>
                </Col>
                <Col sm>
                    <br />
                    {page}
                </Col>
            </Row>
        </Container>
    );
}

export default Admin;