import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Card, Button, Modal, Form } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin1() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateEduYearsTable, setStateEduYearsTable] = useState(null);
    const [stateAddModalShow, setStateAddModalShow] = useState(false);

    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteEduYearID, setStateDeleteEduYearID] = useState(null);
    
    const eduYearInputRef = useRef();

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = a.name.toLowerCase();
                        let y1 = b.name.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStateEduYearsTable(data);
                } else {
                    setStateEduYearsTable(null);
                }
            });

        return () => {
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddModalShow(true);
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handleAdd(e) {
        e.preventDefault();

        const msg = {
            command: "INSERT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: eduYearInputRef.current.value
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    setStateAddModalShow(false);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateEduYearsTable(data);
                            } else {
                                setStateEduYearsTable(null);
                            }
                        });
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        setStateDeleteEduYearID(e.target.getAttribute("id"));
        setStateDeleteModalShow(true);
    }

    function handleDelete(e) {
        e.preventDefault();

        //console.log(e.target.getAttribute("id"));

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateDeleteEduYearID
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_edu_year.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateEduYearsTable(data);
                                setStateDeleteModalShow(false);
                            } else {
                                setStateEduYearsTable(null);
                            }
                        });
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEdit(e) {
        e.preventDefault();

        console.log(e.target.getAttribute("id"));
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    let eduYearsTableData = null;

    if (stateEduYearsTable) {
        eduYearsTableData = stateEduYearsTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center">{index + 1}</div></td>
                <td><div align="center">{object.name}</div></td>
                <td><div align="center"><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center"><Button id={object.id} variant="success" size="sm" onClick={handleEdit} text="#FFFFFF">แก้ไข</Button></div></td>
            </tr>
        });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}><b>เพิ่ม/ลบ/แก้ไข ปีการศึกษา</b></Card.Header>
                <Card.Body>
                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่ม</Button>
                    <br />
                    <br />
                    <Table striped bordered size="sm">
                        <thead>
                            <tr>
                                <th><div align="center">ที่</div></th>
                                <th><div align="center">ปีการศึกษา</div></th>
                                <th><div align="center"></div></th>
                                <th><div align="center"></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {eduYearsTableData}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกปีการศึกษา</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form.Control type="input" ref={eduYearInputRef} placeholder="ปีการศึกษา" />
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบปีการศึกษา</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
        </div>
    );
}

export default Admin1;