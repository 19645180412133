import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Table, Button, Card, Row, Col, Container, Modal, Pagination, Form, Alert } from "react-bootstrap";
import { API } from "../../API";

global.Buffer = global.Buffer || require("buffer").Buffer

const api = API();

function Admin4() {
    let addErrorMsg = [];
    let editErrorMsg = [];

    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateStudentsTable, setStateStudentsTable] = useState(null);
    const [stateShownStudentsTable, setStateShownStudentsTable] = useState(null);
    const [stateShownStudentsPage, setStateShownStudentsPage] = useState(null);
    const [stateTotalStudentsPage, setStateTotalStudentsPage] = useState(null);
    const [statePaginationLabel, setStatePaginationLabel] = useState(null);

    const [stateAddModalShow, setStateAddModalShow] = useState(false);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState(null);
    const [stateAddID, setStateAddID] = useState(null);
    const [stateAddPrefixTable, setStateAddPrefixTable] = useState(null);
    const [stateAddPrefixID, setStateAddPrefixID] = useState(null);
    const [stateSelectedPrefix, setStateSelectedAddPrefix] = useState(null);
    const [stateAddFirstName, setStateAddFirstName] = useState(null);
    const [stateAddLastName, setStateAddLastName] = useState(null);
    const [stateAddEmail, setStateAddEmail] = useState(null);

    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteID, setStateDeleteID] = useState("");

    const [stateEditModalShow, setStateEditModalShow] = useState(false);
    const [stateEditID, setStateEditID] = useState("");
    const [stateEditPrefixID, setStateEditPrefixID] = useState(null);
    const [stateEditFirstName, setStateEditFirstName] = useState("");
    const [stateEditLastName, setStateEditLastName] = useState("");
    const [stateEditEmail, setStateEditEmail] = useState("");

    const [stateEditErrorShow, setStateEditErrorShow] = useState(false);
    const [stateEditErrorMsg, setStateEditErrorMsg] = useState(null);
    const [stateEditPrefixTable, setStateEditPrefixTable] = useState(null);
    const [stateEditSelectedPrefix, setStateSelectedEditPrefix] = useState(null);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];

                        data[i].email = data[i].email.replace(/\s/g, "");
                    }

                    setStateStudentsTable(null);
                    setStateStudentsTable(data);

                    let page = 1;

                    setStateShownStudentsPage(page);
                    setStateTotalStudentsPage(Math.ceil((data.length) / 100));
                    setStatePaginationLabel(`${page}/${Math.ceil((data.length) / 100)}`);

                    let shownStudentTable = [];

                    for (let i = 0; i < (data.length < 100 ? data.length : 100); i++) {
                        shownStudentTable[i] = data[i];
                    }

                    setStateShownStudentsTable(shownStudentTable);
                    //console.log(shownStudentTable);
                } else {
                    setStateStudentsTable(null);
                }
            });
        return () => {
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleFirst(e) {
        e.preventDefault();

        let page = 1;

        setStateShownStudentsPage(page);
        setStateTotalStudentsPage(Math.ceil((stateStudentsTable.length) / 100));
        setStatePaginationLabel(`${page}/${Math.ceil((stateStudentsTable.length) / 100)}`);

        let shownStudentTable = [];

        for (let i = 0; i < (stateStudentsTable.length - ((page - 1) * 100) < 100 ? stateStudentsTable.length - ((page - 1) * 100) : 100); i++) {
            shownStudentTable[i] = stateStudentsTable[i + ((page - 1) * 100)];
        }

        setStateShownStudentsTable(null);
        setStateShownStudentsTable(shownStudentTable);
    }

    function handlePrev(e) {
        e.preventDefault();

        let page = stateShownStudentsPage - 1;

        setStateShownStudentsPage(page);
        setStateTotalStudentsPage(Math.ceil((stateStudentsTable.length) / 100));
        setStatePaginationLabel(`${page}/${Math.ceil((stateStudentsTable.length) / 100)}`);

        let shownStudentTable = [];

        for (let i = 0; i < (stateStudentsTable.length - ((page - 1) * 100) < 100 ? stateStudentsTable.length - ((page - 1) * 100) : 100); i++) {
            shownStudentTable[i] = stateStudentsTable[i + ((page - 1) * 100)];
        }

        setStateShownStudentsTable(null);
        setStateShownStudentsTable(shownStudentTable);
    }

    function handleNext(e) {
        e.preventDefault();

        let page = stateShownStudentsPage + 1;

        setStateShownStudentsPage(page);
        setStateTotalStudentsPage(Math.ceil((stateStudentsTable.length) / 100));
        setStatePaginationLabel(`${page}/${Math.ceil((stateStudentsTable.length) / 100)}`);

        let shownStudentTable = [];

        for (let i = 0; i < (stateStudentsTable.length - ((page - 1) * 100) < 100 ? stateStudentsTable.length - ((page - 1) * 100) : 100); i++) {
            shownStudentTable[i] = stateStudentsTable[i + ((page - 1) * 100)];
        }

        setStateShownStudentsTable(null);
        setStateShownStudentsTable(shownStudentTable);
    }

    function handleLast(e) {
        e.preventDefault();

        let page = Math.ceil((stateStudentsTable.length) / 100);

        setStateShownStudentsPage(Math.ceil((stateStudentsTable.length) / 100));
        setStateTotalStudentsPage(Math.ceil((stateStudentsTable.length) / 100));
        setStatePaginationLabel(`${page}/${Math.ceil((stateStudentsTable.length) / 100)}`);

        let shownStudentTable = [];

        for (let i = 0; i < (stateStudentsTable.length - ((page - 1) * 100) < 100 ? stateStudentsTable.length - ((page - 1) * 100) : 100); i++) {
            shownStudentTable[i] = stateStudentsTable[i + ((page - 1) * 100)];
        }

        setStateShownStudentsTable(null);
        setStateShownStudentsTable(shownStudentTable);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddModalShow(true);
        setStateAddErrorShow(false);

        setStateAddID("");
        setStateAddFirstName("");
        setStateAddLastName("");
        setStateAddEmail("");

        let msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        let json = JSON.stringify(msg);

        let options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student_prefix.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    setStateAddPrefixTable(data);
                    setStateAddPrefixID(data[0].id)
                    setStateSelectedAddPrefix(data[0].name);
                }
            });
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handleAddIDChange(e) {
        const re = /^[0-9\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            if (e.target.value.length <= 5) {
                setStateAddID(e.target.value);
            }
        }
    }

    function handleAddPrefixClick(e) {
        e.preventDefault();

        setStateAddPrefixID(e.target.getAttribute("id"));
        setStateSelectedAddPrefix(e.target.getAttribute("prefix"));
    }

    function handleAddFirstNameChange(e) {
        const re = /^[ก-๏\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateAddFirstName(e.target.value);
        }
    }

    function handleAddLastNameChange(e) {
        const re = /^[ก-๏\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateAddLastName(e.target.value);
        }
    }

    function handleAddEmailChange(e) {
        const re = /^[a-zA-Z0-9+-.@_\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateAddEmail(e.target.value);
        }
    }

    function handleAdd(e) {
        e.preventDefault();

        if (stateAddID.length === 5 && stateAddFirstName.length > 0 && stateAddLastName.length > 0) {
            setStateAddErrorShow(false);

            const msg = {
                command: "INSERT",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    id: stateAddID,
                    prefix_id: stateAddPrefixID,
                    first_name: stateAddFirstName,
                    last_name: stateAddLastName,
                    email: stateAddEmail,
                }
            };

            const json = JSON.stringify(msg);

            //console.log(json);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    //console.log(data);

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: ""
                        };

                        const json = JSON.stringify(msg);

                        //console.log(json);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                //console.log(data);

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];

                                        data[i].email = data[i].email.replace(/\s/g, "");
                                    }

                                    setStateStudentsTable(null);
                                    setStateStudentsTable(data);

                                    let page = 1;

                                    setStateShownStudentsPage(page);
                                    setStateTotalStudentsPage(Math.ceil((data.length) / 100));
                                    setStatePaginationLabel(`${page}/${Math.ceil((data.length) / 100)}`);

                                    let shownStudentTable = [];

                                    for (let i = 0; i < (data.length < 100 ? data.length : 100); i++) {
                                        shownStudentTable[i] = data[i];
                                    }

                                    setStateShownStudentsTable(shownStudentTable);
                                    //console.log(shownStudentTable);

                                    setStateAddModalShow(false);
                                } else {
                                    setStateStudentsTable(null);
                                }
                            });
                    } else {
                        addErrorMsg = [];
                        setStateAddErrorShow(true);

                        addErrorMsg.push("เลขประจำตัวนักเรียนซ้ำกัน");

                        setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            addErrorMsg = [];
            setStateAddErrorShow(true);

            if (stateAddID.length !== 5) {
                addErrorMsg.push("เลขประจำตัวนักเรียนจะต้องมี 5 หลัก");
            }

            if (stateAddFirstName.length === 0) {
                addErrorMsg.push("กรุณากรอกชื่อ");
            }

            if (stateAddLastName.length === 0) {
                addErrorMsg.push("กรุณากรอกนามสกุล");
            }

            if (stateAddEmail.length === 0) {
                addErrorMsg.push("กรุณากรอกอีเมล์");
            }

            setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }


    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteModalShow(true);
        setStateDeleteID(e.target.getAttribute("id"));
    }


    function handleDelete(e) {
        e.preventDefault();

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateDeleteID
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];

                                    data[i].email = data[i].email.replace(/\s/g, "");
                                }

                                setStateStudentsTable(null);
                                setStateStudentsTable(data);

                                let page = stateShownStudentsPage;

                                if(stateShownStudentsPage > Math.ceil((data.length) / 100)) {
                                    page = page - 1;
                                    setStateShownStudentsPage(page);
                                }

                                let shownStudentTable = [];

                                for (let i = 0; i < (data.length - ((page - 1) * 100) < 100 ? data.length - ((page - 1) * 100) : 100); i++) {
                                    shownStudentTable[i] = data[i + ((page - 1) * 100)];
                                }
                        
                                setStateShownStudentsTable(null);
                                setStateShownStudentsTable(shownStudentTable);

                                // let page = 1;

                                // setStateShownStudentsPage(page);
                                // setStateTotalStudentsPage(Math.ceil((data.length) / 100));
                                // setStatePaginationLabel(`${page}/${Math.ceil((data.length) / 100)}`);

                                // let shownStudentTable = [];

                                // for (let i = 0; i < (data.length < 100 ? data.length : 100); i++) {
                                //     shownStudentTable[i] = data[i];
                                // }

                                // setStateShownStudentsTable(shownStudentTable);
                                //console.log(shownStudentTable);

                                setStateDeleteModalShow(false);
                            } else {
                                setStateStudentsTable(null);
                            }
                        });
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {
        e.preventDefault();

        setStateEditErrorShow(false);

        const msg = {
            command: "SELECT_IND",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: e.target.getAttribute("id")
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data1 = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data1);

                if (data1[0] !== "fail") {
                    let msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    let json = JSON.stringify(msg);

                    let options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_student_prefix.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data2 = JSON.parse(Buffer.from(res.data, "base64").toString());

                            // console.log(data2);

                            if (data2[0] !== "fail") {
                                for (let i = 0; i < data1.length; i++) {
                                    data1[i] = data1[i][0];

                                    data1[i].email = data1[i].email.replace(/\s/g, "");
                                }

                                for (let i = 0; i < data2.length; i++) {
                                    data2[i] = data2[i][0];
                                }

                                setStateEditPrefixTable(data2);
                                setStateEditPrefixID(data1[0].prefix_id);
                                setStateSelectedEditPrefix(data1[0].prefix_name);

                                setStateEditID(data1[0].id);
                                setStateEditPrefixID(data1[0].prefix_id);
                                setStateEditFirstName(data1[0].first_name);
                                setStateEditLastName(data1[0].last_name);
                                setStateEditEmail(data1[0].email);

                                setStateEditModalShow(true);
                            } else {

                            }
                        });
                } else {
                }
            });
    }

    function handleEditModalHide(e) {
        setStateEditModalShow(false);
        setStateEditErrorShow(false);
    }

    function handleEditPrefixClick(e) {
        e.preventDefault();

        setStateEditPrefixID(e.target.getAttribute("id"));
        setStateSelectedEditPrefix(e.target.getAttribute("prefix"));
    }

    function handleEditFirstNameChange(e) {
        const re = /^[ก-๏\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateEditFirstName(e.target.value);
        }
    }

    function handleEditLastNameChange(e) {
        const re = /^[ก-๏\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateEditLastName(e.target.value);
        }
    }

    function handleEditEmailChange(e) {
        const re = /^[a-zA-Z0-9+-.@_\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateEditEmail(e.target.value);
        }
    }

    function handleEdit(e) {
        e.preventDefault();

        if (stateEditFirstName.length > 0 && stateEditLastName.length > 0) {
            setStateAddErrorShow(false);

            const msg = {
                command: "UPDATE",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    id: stateEditID,
                    prefix_id: stateEditPrefixID,
                    first_name: stateEditFirstName,
                    last_name: stateEditLastName,
                    email: stateEditEmail,
                }
            };

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    //console.log(data);

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: ""
                        };

                        const json = JSON.stringify(msg);

                        //console.log(json);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                //console.log(data);

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];

                                        data[i].email = data[i].email.replace(/\s/g, "");
                                    }

                                    setStateStudentsTable(null);
                                    setStateStudentsTable(data);

                                    let shownStudentTable = [];
                                    let page = stateShownStudentsPage;

                                    for (let i = 0; i < (data.length - ((page - 1) * 100) < 100 ? data.length - ((page - 1) * 100) : 100); i++) {
                                        shownStudentTable[i] = data[i + ((page - 1) * 100)];
                                    }
                            
                                    setStateShownStudentsTable(null);
                                    setStateShownStudentsTable(shownStudentTable);

                                    // let page = 1;

                                    // setStateShownStudentsPage(page);
                                    // setStateTotalStudentsPage(Math.ceil((data.length) / 100));
                                    // setStatePaginationLabel(`${page}/${Math.ceil((data.length) / 100)}`);

                                    // let shownStudentTable = [];

                                    // for (let i = 0; i < (data.length < 100 ? data.length : 100); i++) {
                                    //     shownStudentTable[i] = data[i];
                                    // }

                                    // setStateShownStudentsTable(shownStudentTable);
                                    //console.log(shownStudentTable);

                                    setStateEditModalShow(false);
                                } else {
                                    setStateStudentsTable(null);
                                }
                            });
                    } else {
                        editErrorMsg = [];
                        setStateEditErrorShow(true);

                        editErrorMsg.push("การแก้ไขข้อมูลผิดพลาด");

                        setStateEditErrorMsg(editErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            editErrorMsg = [];
            setStateEditErrorShow(true);

            if (stateEditFirstName.length === 0) {
                editErrorMsg.push("กรุณากรอกชื่อ");
            }

            if (stateEditLastName.length === 0) {
                editErrorMsg.push("กรุณากรอกนามสกุล");
            }

            setStateEditErrorMsg(editErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let shownStudentDataTable = null;

    if (stateShownStudentsTable) {
        shownStudentDataTable = stateShownStudentsTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{((stateShownStudentsPage - 1) * 100) + index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.id}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>{object.email}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="success" size="sm" onClick={handleEditClick} text="#FFFFFF">แก้ไข</Button></div></td>
            </tr>
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let addPrefixDropdownData = null;

    if (stateAddPrefixTable) {
        addPrefixDropdownData = stateAddPrefixTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} prefix={object.name} onClick={handleAddPrefixClick}>{object.name}</Dropdown.Item>;
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let editPrefixDropdownData = null;

    if (stateEditPrefixTable) {
        editPrefixDropdownData = stateEditPrefixTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} prefix={object.name} onClick={handleEditPrefixClick}>{object.name}</Dropdown.Item>;
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>เพิ่ม/ลบ/แก้ไข นักเรียน</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <Table size="sm" borderless={true}>
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px", display: "flex", justifyContent: "center" }}>
                                    <Pagination>
                                        <Pagination.First onClick={handleFirst} disabled={stateShownStudentsPage === 1} />
                                        <Pagination.Prev onClick={handlePrev} disabled={stateShownStudentsPage === 1} />
                                        <Pagination.Item disabled={true}>{statePaginationLabel ? statePaginationLabel : "-"}</Pagination.Item>
                                        <Pagination.Next onClick={handleNext} disabled={stateShownStudentsPage === stateTotalStudentsPage} />
                                        <Pagination.Last onClick={handleLast} disabled={stateShownStudentsPage === stateTotalStudentsPage} />
                                    </Pagination>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่มนักเรียน</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table striped bordered size="sm" responsive="xxl">
                        <thead>
                            <tr>
                                <th><div align="center">ที่</div></th>
                                <th><div align="center">เลขประจำตัวฯ</div></th>
                                <th><div align="center">คำนำหน้า</div></th>
                                <th><div align="center">ชื่อ</div></th>
                                <th><div align="center">นามสกุล</div></th>
                                <th><div align="center">อีเมล์</div></th>
                                <th><div align="center"></div></th>
                                <th><div align="center"></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {shownStudentDataTable}
                        </tbody>
                    </Table>
                    <Table size="sm" borderless={true}>
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px", display: "flex", justifyContent: "center" }}>
                                    <Pagination>
                                        <Pagination.First onClick={handleFirst} disabled={stateShownStudentsPage === 1} />
                                        <Pagination.Prev onClick={handlePrev} disabled={stateShownStudentsPage === 1} />
                                        <Pagination.Item disabled={true}>{statePaginationLabel ? statePaginationLabel : "-"}</Pagination.Item>
                                        <Pagination.Next onClick={handleNext} disabled={stateShownStudentsPage === stateTotalStudentsPage} />
                                        <Pagination.Last onClick={handleLast} disabled={stateShownStudentsPage === stateTotalStudentsPage} />
                                    </Pagination>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกข้อมูลนักเรียน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddID} onChange={handleAddIDChange} placeholder="เลขประจำตัวนักเรียน" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    คำนำหน้า :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedPrefix}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {addPrefixDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddFirstName} onChange={handleAddFirstNameChange} placeholder="ชื่อ" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddLastName} onChange={handleAddLastNameChange} placeholder="นามสกุล" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddEmail} onChange={handleAddEmailChange} placeholder="อีเมล์" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบนักเรียน</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateEditModalShow} onHide={handleEditModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณาแก้ไขข้อมูลนักเรียน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateEditErrorShow}>{stateEditErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditID} placeholder="เลขประจำตัวนักเรียน" disabled />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    คำนำหน้า :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateEditSelectedPrefix}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {editPrefixDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditFirstName} onChange={handleEditFirstNameChange} placeholder="ชื่อ" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditLastName} onChange={handleEditLastNameChange} placeholder="นามสกุล" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditEmail} onChange={handleEditEmailChange} placeholder="อีเมล์" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEdit} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal >
        </div>
    );
}

export default Admin4;