import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Card, Container, Col, Row, ProgressBar } from "react-bootstrap";
import { API } from "../../API";
import XLSX from 'xlsx';

global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin99() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateSelectedFile1, setStateSelectedFile1] = useState(null);
    const [stateSelectedFile2, setStateSelectedFile2] = useState(null);
    const [stateSelectedFile3, setStateSelectedFile3] = useState(null);

    const [stateProgress1, setStateProgress1] = useState(0);
    const [stateProgress1Label, setStateProgress1Label] = useState("");
    const [stateProgress2, setStateProgress2] = useState(0);
    const [stateProgress2Label, setStateProgress2Label] = useState("");
    const [stateProgress3, setStateProgress3] = useState(0);
    const [stateProgress3Label, setStateProgress3Label] = useState("");


    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    function handleFile1Seleted(e) {
        setStateSelectedFile1(e.target.files[0]);
    }

    function handleFile2Seleted(e) {
        setStateSelectedFile2(e.target.files[0]);
    }

    function handleFile3Seleted(e) {
        setStateSelectedFile3(e.target.files[0]);
    }

    function handleInitDatabase1(e) {
        e.preventDefault();

        if (stateSelectedFile1 !== null && stateSelectedFile1.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

            setStateProgress1(0);
            setStateProgress1Label("");

            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(stateSelectedFile1);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = XLSX.utils.sheet_to_json(ws);

                    resolve(data);
                };

                fileReader.onerror = (e) => {
                    reject(e);
                };
            });

            promise.then((data) => {
                let students = data;

                //console.log(students);

                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor(loaded * 100 / total);
                        setStateProgress1(percent);
                        setStateProgress1Label(`${percent}%`);
                    },
                    header: {
                        "content-type": "text/plain"
                    }
                };

                const msg = {
                    command: "INSERT_START",
                    id: reduxState.userID,
                    password: reduxState.userPassword,
                    edu_year: stateSelectedEduYear,
                    edu_term: stateSelectedEduTerm,
                    data: students
                };

                //console.log(msg);

                const json = JSON.stringify(msg);

                api.post("admin_init_students_and_classrooms.php", Buffer.from(json).toString("base64"), options)
                    .then(res => {
                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());
                        //console.log(data[0]);

                        if (data[0] === "pass") {
                            setStateProgress1Label("สำเร็จแล้ว");
                        }
                    });
            })
        }
    }

    function handleInitDatabase2(e) {
        e.preventDefault();

        if (stateSelectedFile2 !== null && stateSelectedFile2.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(stateSelectedFile2);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = XLSX.utils.sheet_to_json(ws);

                    resolve(data);
                };

                fileReader.onerror = (e) => {
                    reject(e);
                };
            });

            promise.then((data) => {
                let teachers = data;

                //console.log(teachers);

                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor(loaded * 100 / total);
                        setStateProgress2(percent);
                        setStateProgress2Label(`${percent}%`);
                    },
                    header: {
                        "content-type": "text/plain"
                    }
                };

                const msg = {
                    command: "INSERT_START",
                    id: reduxState.userID,
                    password: reduxState.userPassword,
                    data: teachers
                };

                // //console.log(msg);

                const json = JSON.stringify(msg);

                api.post("admin_init_teachers.php", Buffer.from(json).toString("base64"), options)
                    .then(res => {
                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                        if (data[0] === "pass") {
                            setStateProgress2Label("สำเร็จแล้ว");
                        }
                    });
            })
        }
    }

    function handleInitDatabase3(e) {
        e.preventDefault();

        if (stateSelectedFile3 !== null && stateSelectedFile3.type ===
            "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet") {

            const promise = new Promise((resolve, reject) => {
                const fileReader = new FileReader();
                fileReader.readAsArrayBuffer(stateSelectedFile3);

                fileReader.onload = (e) => {
                    const bufferArray = e.target.result;
                    const wb = XLSX.read(bufferArray, { type: "buffer" });
                    const ws = wb.Sheets[wb.SheetNames[0]];
                    const data = XLSX.utils.sheet_to_json(ws);

                    resolve(data);
                };

                fileReader.onerror = (e) => {
                    reject(e);
                };
            });

            promise.then((data) => {
                let class_teachers = data;

                for (let i = 0; i < class_teachers.length; i++) {
                    class_teachers[i].level = Number.parseInt(class_teachers[i].level.replace(/ม./, ""));
                    class_teachers[i].room = Number.parseInt(class_teachers[i].room);
                    class_teachers[i].edu_term = Number.parseInt(class_teachers[i].edu_term);
                }

                //console.log(class_teachers);

                const options = {
                    onUploadProgress: (progressEvent) => {
                        const { loaded, total } = progressEvent;
                        let percent = Math.floor(loaded * 100 / total);
                        setStateProgress3(percent);
                        setStateProgress3Label(`${percent}%`);
                    },
                    header: {
                        "content-type": "text/plain"
                    }
                };

                const msg = {
                    command: "INSERT_START",
                    id: reduxState.userID,
                    password: reduxState.userPassword,
                    data: {
                        class_teacher: class_teachers,
                        edu_year_id: stateSelectedEduYearId,
                        edu_term: stateSelectedEduTerm
                    }
                };

                // //console.log(msg);

                const json = JSON.stringify(msg);

                api.post("admin_init_class_teachers.php", Buffer.from(json).toString("base64"), options)
                    .then(res => {
                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                        if (data[0] === "pass") {
                            setStateProgress3Label("สำเร็จแล้ว");
                        }
                    });
            })
        }
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>สร้างฐานข้อมูลนักเรียนและห้องเรียนเริ่มต้น</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <ProgressBar now={stateProgress1} label={stateProgress1Label} /><br />
                    <Form.Control type="file" onChange={handleFile1Seleted} accept=".xlsx" disabled/><br />
                    <Button variant="success" size="md" text="#FFFFFF" onClick={handleInitDatabase1} disabled>ตกลง</Button>
                </Card.Body>
            </Card><br />
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>สร้างฐานข้อมูลครูเริ่มต้น</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <ProgressBar now={stateProgress2} label={stateProgress2Label} /><br />
                    <Form.Control type="file" onChange={handleFile2Seleted} accept=".xlsx" disabled/><br />
                    <Button variant="success" size="md" text="#FFFFFF" onClick={handleInitDatabase2} disabled>ตกลง</Button>
                </Card.Body>
            </Card>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>สร้างฐานข้อมูลภาระงานสอนเริ่มต้น</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <ProgressBar now={stateProgress3} label={stateProgress3Label} /><br />
                    <Form.Control type="file" onChange={handleFile3Seleted} accept=".xlsx" disabled/><br />
                    <Button variant="success" size="md" text="#FFFFFF" onClick={handleInitDatabase3} disabled>ตกลง</Button>
                </Card.Body>
            </Card>
        </div>
    );
}

export default Admin99;