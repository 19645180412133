import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Button, Card, Container, Col, Row, Table, Modal, Alert } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin82() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    let addErrorMsg = [];

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateWorkTable, setStateWorkTable] = useState(null);

    const [stateAddModalShow, setStateAddModalShow] = useState(false);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState("");
    const [stateSelectedPerson, setStateSelectedPerson] = useState(null);
    const [stateSelectedPersonID, setStateSelectedPersonID] = useState(null);
    const [statePersonTable, setStatePersonTable] = useState(null);
    const [stateSelectedSubject, setStateSelectedSubject] = useState(null);
    const [stateSelectedSubjectID, setStateSelectedSubjectID] = useState(null);
    const [stateSubjectTable, setStateSubjectTable] = useState(null);
    const [stateSelectedLevel, setStateSelectedLevel] = useState("1");
    const [stateLevelsTable, setStateLevelsTable] = useState(null);
    const [stateSelectedRoom, setStateSelectedRoom] = useState("1");
    const [stateRoomsTable, setStateRoomsTable] = useState(null);
    const [stateEnable, setStateEnable] = useState(false);

    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteSubjectId, setStateDeleteSubjectId] = useState(null);
    const [stateDeleteTeacherId, setStateDeleteTeacherId] = useState(null);
    const [stateDeleteClassroomId, setStateDeleteClassroomId] = useState(null);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: data[0].edu_years_id,
                            edu_term: data[0].edu_term
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_work.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateWorkTable(data);
                            } else {
                                setStateWorkTable(null);
                            }
                        });
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();
        setStateEnable(false);

        setStatePersonTable(null);
        setStateSelectedPerson(null);
        setStateSubjectTable(null);
        setStateSelectedSubject(null);
        setStateLevelsTable(null);
        setStateSelectedLevel("1");
        setStateRoomsTable(null);
        setStateSelectedRoom("1");

        const msg = {
            command: "SELECT_TEACHER",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    setStatePersonTable(data);
                    setStateSelectedPerson(`${data[0].first_name} ${data[0].last_name}`);
                    setStateSelectedPersonID(data[0].id);

                    const msg2 = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json2 = JSON.stringify(msg2);

                    const options2 = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_subject.php", Buffer.from(json2).toString("base64"), options2)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateSubjectTable(data);
                                setStateSelectedSubject(`${data[0].code} ${data[0].name}`);
                                setStateSelectedSubjectID(data[0].id);

                                const msg3 = {
                                    command: "SELECT",
                                    id: reduxState.userID,
                                    password: reduxState.userPassword,
                                    data: {
                                        edu_year_id: stateSelectedEduYearId,
                                        edu_term: stateSelectedEduTerm
                                    }
                                };

                                const json3 = JSON.stringify(msg3);

                                //console.log(json);

                                const options3 = {
                                    header: {
                                        "content-type": "text/plain"
                                    }
                                };

                                api.post("admin_classroom.php", Buffer.from(json3).toString("base64"), options3)
                                    .then(res => {
                                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                        //console.log(data);

                                        if (data[0] !== "fail") {
                                            for (let i = 0; i < data.length; i++) {
                                                data[i] = data[i][0];
                                            }

                                            let level = [];

                                            for (let i = 0; i < data.length; i++) {
                                                let insert = true;

                                                for (let j = 0; j < level.length; j++) {
                                                    if (level[j].level === data[i].level) {
                                                        insert = false;
                                                    }
                                                }

                                                if (insert) {
                                                    level.push({ level: data[i].level });
                                                }
                                            }

                                            //console.log(level);

                                            setStateLevelsTable(level);
                                            setStateSelectedLevel(level[0].level);

                                            const roomTemp = data.filter(item => item.level === level[0].level);
                                            //console.log(rooms);

                                            let room = [];

                                            for (let i = 0; i < roomTemp.length; i++) {
                                                room.push({ room: roomTemp[i].room });
                                            }

                                            setStateRoomsTable(room);
                                            setStateSelectedRoom(room[0].room);

                                            setStateEnable(true);
                                        }
                                    });
                            } else {
                                setStateSubjectTable(null);
                                setStateSelectedSubject(null);
                                setStateSelectedSubjectID(null);
                            }
                        });

                } else {
                    setStatePersonTable(null);
                    setStateSelectedPerson(null);
                    setStateSelectedPersonID(null);
                }
            });

        setStateAddModalShow(true);
        setStateAddErrorShow(false);
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handlePersonClick(e) {
        setStateSelectedPerson(e.target.getAttribute("label"));
        setStateSelectedPersonID(e.target.getAttribute("id"));

        console.log(e.target.getAttribute("id"));
    }

    function handleSubjectClick(e) {
        setStateSelectedSubject(e.target.getAttribute("label"));
        setStateSelectedSubjectID(e.target.getAttribute("id"));

        console.log(e.target.getAttribute("id"));
    }

    function handleLevelClick(e) {
        setStateSelectedLevel(e.target.getAttribute("level"));

        console.log(e.target.getAttribute("level"));

        setStateEnable(false);

        const msg3 = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json3 = JSON.stringify(msg3);

        //console.log(json);

        const options3 = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_classroom.php", Buffer.from(json3).toString("base64"), options3)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    const roomTemp = data.filter(item => item.level === e.target.getAttribute("level"));
                    //console.log(rooms);

                    let room = [];

                    for (let i = 0; i < roomTemp.length; i++) {
                        room.push({ room: roomTemp[i].room });
                    }

                    setStateRoomsTable(room);
                    setStateSelectedRoom(room[0].room);
                    setStateEnable(true);
                }
            });
    }

    function handleRoomClick(e) {
        setStateSelectedRoom(e.target.getAttribute("room"));

        console.log(e.target.getAttribute("room"));
    }

    function handleAdd(e) {
        e.preventDefault();

        const msg = {
            command: "INSERT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                t_id: stateSelectedPersonID,
                s_id: stateSelectedSubjectID,
                level: stateSelectedLevel,
                room: stateSelectedRoom,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        //console.log(msg);

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_work.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_work.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateWorkTable(data);
                                setStateAddModalShow(false);
                                setStateAddErrorShow(false);
                            } else {
                                setStateWorkTable(null);
                            }
                        });
                }
                else {
                    addErrorMsg = [];
                    setStateAddErrorShow(true);

                    // if (stateAddStd1 === "") {
                    addErrorMsg.push("การเพิ่มภาระงานผิดพลาดหรือภาระงานซ้ำ");
                    // }

                    setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                        return <div key={index}>{`- `}{object}</div>;
                    }));
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteSubjectId(e.target.getAttribute("s_id"));
        setStateDeleteTeacherId(e.target.getAttribute("t_id"));
        setStateDeleteClassroomId(e.target.getAttribute("c_id"));
        setStateDeleteModalShow(true);

        console.log(e.target.getAttribute("t_id"));
        console.log(e.target.getAttribute("id"));
        // console.log(e.target.getAttribute("c_id"));
    }

    function handleDelete(e) {
        e.preventDefault();

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                subject_id: stateDeleteSubjectId,
                teacher_id: stateDeleteTeacherId,
                classroom_id: stateDeleteClassroomId,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_work.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_work.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateWorkTable(data);
                                setStateDeleteModalShow(false);
                            } else {
                                setStateWorkTable(null);
                            }
                        });
                } else {
                    setStateWorkTable(null);
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEdit(e) {
        e.preventDefault();

        //console.log(e.target.getAttribute("id"));
    }

    let workTableData = null;

    if (stateWorkTable) {
        workTableData = stateWorkTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center">{index + 1}</div></td>
                <td><div align="left">{object.first_name}</div></td>
                <td><div align="left">{object.last_name}</div></td>
                <td><div align="center">{object.subject_code}</div></td>
                <td><div align="left">{object.subject_name}</div></td>
                <td><div align="center">{object.level}</div></td>
                <td><div align="center">{object.room}</div></td>
                <td><div align="center"><Button id={object.id} t_id={object.teacher_id} s_id={object.subject_id} c_id={object.classroom_id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center"><Button id={object.id} t_id={object.teacher_id} s_id={object.subject_id} c_id={object.classroom_id} variant="success" size="sm" onClick={handleEdit} text="#FFFFFF" disabled>แก้ไข</Button></div></td>
            </tr>
        });
    }

    let personDropdownData = null;

    if (statePersonTable) {
        personDropdownData = statePersonTable.map(function (object, i) {
            return <Dropdown.Item key={i} order={i + 1} label={`${object.first_name} ${object.last_name}`} id={object.id} onClick={handlePersonClick}>{`${object.first_name} ${object.last_name}`}</Dropdown.Item>;
        });
    }

    let subjectDropdownData = null;

    if (stateSubjectTable) {
        subjectDropdownData = stateSubjectTable.map(function (object, i) {
            return <Dropdown.Item key={i} order={i + 1} label={`${object.code} ${object.name}`} id={object.id} onClick={handleSubjectClick}>{`${object.code} ${object.name}`}</Dropdown.Item>;
        });
    }

    let levelDropdownData = null;

    if (stateLevelsTable) {
        levelDropdownData = stateLevelsTable.map(function (object, i) {
            return <Dropdown.Item key={i} level={object.level} onClick={handleLevelClick}>{`ชั้นมัธยมศึกษาปีที่ ${object.level}`}</Dropdown.Item>;
        });
    }

    let roomDropdownData = null;

    if (stateRoomsTable) {
        roomDropdownData = stateRoomsTable.map(function (object, i) {
            return <Dropdown.Item key={i} room={object.room} onClick={handleRoomClick}>{`ห้อง ${object.room}`}</Dropdown.Item>;
        });
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>เพิ่ม/ลบ/แก้ไข ภาระงานสอน</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่มภาระงานสอน</Button>
                    <br />
                    <br />
                    <div>
                        <Table striped bordered size="sm" responsive="xxl">
                            <thead>
                                <tr>
                                    <th><div align="center">ที่</div></th>
                                    <th><div align="center">ชื่อ</div></th>
                                    <th><div align="center">นามสกุล</div></th>
                                    <th><div align="center">รหัสวิชา</div></th>
                                    <th><div align="center">ชื่อวิชา</div></th>
                                    <th><div align="center">ชั้น</div></th>
                                    <th><div align="center">ห้อง</div></th>
                                    <th><div align="center"></div></th>
                                    <th><div align="center"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {workTableData}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="lg">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากำหนดภาระงานสอน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }} colSpan={4}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Table striped bordered size="sm" responsive="sm">
                                        <thead>
                                            <tr>
                                                <th><div align="center">ครูผู้สอน</div></th>
                                                <th><div align="center">วิชาสอน</div></th>
                                                <th><div align="center">ชั้น</div></th>
                                                <th><div align="center">ห้อง</div></th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr>
                                                <td style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" disabled={!stateEnable}>{stateSelectedPerson}</Dropdown.Toggle>
                                                        <Dropdown.Menu variant="dark">
                                                            {personDropdownData}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" disabled={!stateEnable}>{stateSelectedSubject}</Dropdown.Toggle>
                                                        <Dropdown.Menu variant="dark">
                                                            {subjectDropdownData}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" disabled={!stateEnable}>{`ชั้นมัธยมศึกษาปีที่ ${stateSelectedLevel}`}</Dropdown.Toggle>
                                                        <Dropdown.Menu variant="dark">
                                                            {levelDropdownData}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                                <td style={{ paddingLeft: "0px", paddingRight: "0px", textAlign: "center" }}>
                                                    <Dropdown>
                                                        <Dropdown.Toggle variant="dark" disabled={!stateEnable}>{`ห้อง ${stateSelectedRoom}`}</Dropdown.Toggle>
                                                        <Dropdown.Menu variant="dark">
                                                            {roomDropdownData}
                                                        </Dropdown.Menu>
                                                    </Dropdown>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </Table>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md" disabled={!stateEnable}>
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบวิชาสอน</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
        </div>
    );
}

export default Admin82;