import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button, Card, Container, Col, Row, Table, Modal, Alert } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin81() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    let addErrorMsg = [];

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateSubjectTable, setStateSubjectTable] = useState(null);

    const [stateAddModalShow, setStateAddModalShow] = useState(false);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState("");
    const [stateAddCode, setStateAddCode] = useState("");
    const [stateAddName, setStateAddName] = useState("");

    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteSubjectId, setStateDeleteSubjectId] = useState(null);

    const [stateEditModalShow, setStateEditModalShow] = useState(false);
    const [stateEditErrorShow, setStateEditErrorShow] = useState(false);
    const [stateEditErrorMsg, setStateEditErrorMsg] = useState("");
    const [stateEditSubjectId, setStateEditSubjectId] = useState(null);
    const [stateEditCode, setStateEditCode] = useState("");
    const [stateEditName, setStateEditName] = useState("");

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: data[0].edu_years_id,
                            edu_term: data[0].edu_term
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateSubjectTable(data);
                            } else {
                                setStateSubjectTable(null);
                            }
                        });
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddCode("");
        setStateAddName("");
        setStateAddModalShow(true);
        setStateAddErrorShow(false);
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handleAddCodeChange(e) {
        setStateAddCode(e.target.value);
    }

    function handleAddNameChange(e) {
        setStateAddName(e.target.value);
    }

    function handleAdd(e) {
        e.preventDefault();

        if (stateAddCode !== "" && stateAddName !== "") {
            const msg = {
                command: "INSERT",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    code: stateAddCode,
                    name: stateAddName,
                    edu_year_id: stateSelectedEduYearId,
                    edu_term: stateSelectedEduTerm
                }
            };

            //console.log(msg);

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: {
                                edu_year_id: stateSelectedEduYearId,
                                edu_term: stateSelectedEduTerm
                            }
                        };

                        const json = JSON.stringify(msg);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                //console.log(data[0]);

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];
                                    }

                                    setStateSubjectTable(data);
                                } else {
                                    setStateSubjectTable(null);
                                }
                            });

                        setStateAddModalShow(false);
                        setStateAddErrorShow(false);
                    }
                    else {
                        addErrorMsg = [];
                        setStateAddErrorShow(true);

                        // if (stateAddStd1 === "") {
                        addErrorMsg.push("การเพิ่มวิชาผิดพลาดหรือรหัสวิชาซ้ำ");
                        // }

                        setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            addErrorMsg = [];
            setStateAddErrorShow(true);

            if (stateAddCode === "") {
                addErrorMsg.push("กรุณากรอกรหัสวิชา");
            }

            if (stateAddName === "") {
                addErrorMsg.push("กรุณากรอกชื่อวิชา");
            }

            setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteSubjectId(e.target.getAttribute("id"));
        setStateDeleteModalShow(true);

        //console.log(e.target.getAttribute("id"));
    }

    function handleDelete(e) {
        e.preventDefault();

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateDeleteSubjectId
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0]);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStateSubjectTable(data);
                            } else {
                                setStateSubjectTable(null);
                            }
                        });

                    setStateDeleteModalShow(false);
                    setStateAddErrorShow(false);
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {
        e.preventDefault();

        setStateEditSubjectId(e.target.getAttribute("id"));

        const msg = {
            command: "SELECT_IDV",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                s_id: e.target.getAttribute("id"),
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    setStateEditCode(data[0].code);
                    setStateEditName(data[0].name);

                    setStateEditModalShow(true);
                    setStateEditErrorShow(false);
                } else {
                }
            });
    }

    function handleEditModalHide(e) {
        setStateEditModalShow(false);
    }

    function handleEdit(e) {
        e.preventDefault();

        if (stateEditCode !== "" && stateEditName !== "") {
            const msg = {
                command: "UPDATE",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    s_id: stateEditSubjectId,
                    code: stateEditCode,
                    name: stateEditName,
                    edu_year_id: stateSelectedEduYearId,
                    edu_term: stateSelectedEduTerm
                }
            };

            //console.log(msg);

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: {
                                edu_year_id: stateSelectedEduYearId,
                                edu_term: stateSelectedEduTerm
                            }
                        };

                        const json = JSON.stringify(msg);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_subject.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                //console.log(data[0]);

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];
                                    }

                                    setStateSubjectTable(data);
                                } else {
                                    setStateSubjectTable(null);
                                }
                            });

                        setStateEditModalShow(false);
                        setStateEditErrorShow(false);
                    }
                    else {
                        addErrorMsg = [];
                        setStateEditErrorShow(true);

                        // if (stateAddStd1 === "") {
                        addErrorMsg.push("การแก้ไขวิชาผิดพลาดหรือรหัสวิชาซ้ำ");
                        // }

                        setStateEditErrorMsg(addErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            addErrorMsg = [];
            setStateEditErrorShow(true);

            if (stateEditCode === "") {
                addErrorMsg.push("กรุณากรอกรหัสวิชา");
            }

            if (stateEditName === "") {
                addErrorMsg.push("กรุณากรอกชื่อวิชา");
            }

            setStateEditErrorMsg(addErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    function handleEditCodeChange(e) {
        setStateEditCode(e.target.value);
    }

    function handleEditNameChange(e) {
        setStateEditName(e.target.value);
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////    
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    let subjectTableData = null;

    if (stateSubjectTable) {
        subjectTableData = stateSubjectTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center">{index + 1}</div></td>
                <td><div align="center">{object.code}</div></td>
                <td><div align="left">{object.name}</div></td>
                <td><div align="center"><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center"><Button id={object.id} variant="success" size="sm" onClick={handleEditClick} text="#FFFFFF">แก้ไข</Button></div></td>
            </tr>
        });
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>เพิ่ม/ลบ/แก้ไข วิชาสอน</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่มวิชา</Button>
                    <br />
                    <br />
                    <div>
                        <Table striped bordered size="sm" responsive="xxl">
                            <thead>
                                <tr>
                                    <th><div align="center">ที่</div></th>
                                    <th><div align="center">รหัสวิชา</div></th>
                                    <th><div align="center">ชื่อวิชา</div></th>
                                    <th><div align="center"></div></th>
                                    <th><div align="center"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {subjectTableData}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกวิชา</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control value={stateAddCode} onChange={handleAddCodeChange} placeholder="รหัสวิชา" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control value={stateAddName} onChange={handleAddNameChange} placeholder="ชื่อวิชา" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบวิชาสอน</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateEditModalShow} onHide={handleEditModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณาแก้ไขวิชา</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateEditErrorShow}>{stateEditErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control value={stateEditCode} onChange={handleEditCodeChange} placeholder="รหัสวิชา" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control value={stateEditName} onChange={handleEditNameChange} placeholder="ชื่อวิชา" />
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEdit} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    );
}

export default Admin81;