import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Form, Dropdown, Table, Alert } from "react-bootstrap";
import { API } from "../API";
import Cookies from "js-cookie";

const api = API();

function Std3Manager() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateOnStdShow, setStateOnStdShow] = useState(false);
    const [stateOnPersonShow, setStateOnPersonShow] = useState(false);

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateStd3Table, setStateStd3Table] = useState(null);

    const [stateSelectedStd3, setStateSelectedStd3] = useState(null);
    const [stateSelectedStd3ID, setStateSelectedStd3ID] = useState(null);
    const [stateSelectedStd3Label, setStateSelectedStd3Label] = useState(null);
    const [statePassTeacherTable, setStatePassTeacherTable] = useState(null);
    const [stateFailTeacherTable, setStateFailTeacherTable] = useState(null);
    const [stateUnknownTeacherTable, setStateUnknownTeacherTable] = useState(null);

    const [stateSelectedPerson, setStateSelectedPerson] = useState(null);
    const [stateSelectedPersonID, setStateSelectedPersonID] = useState(null);
    const [statePersonTable, setStatePersonTable] = useState(null);
    const [statePersonStd3Table, setStatePersonStd3Table] = useState(null);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });
        return () => {
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleOnStandard(e) {
        setStateOnStdShow(true);
        setStateOnPersonShow(false);

        setStatePassTeacherTable(null);
        setStateFailTeacherTable(null);
        setStateUnknownTeacherTable(null);

        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_standard.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = parseInt(a.id);
                        let y1 = parseInt(b.id);

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStateSelectedStd3("มาตรฐานสถานศึกษาที่ 1");
                    setStateSelectedStd3ID(data[0].id);
                    setStateSelectedStd3Label(data[0].name);

                    setStateStd3Table(data);

                    const msg = {
                        command: "SELECT_PASS_FAIL_UNKNOWN",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            std3_id: data[0].id
                        }
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                if (data[0] !== undefined) {
                                    data[0].sort(function (a, b) {
                                        let x1 = a.first_name;
                                        let y1 = b.first_name;

                                        if (x1 < y1) {
                                            return -1;
                                        }

                                        if (x1 > y1) {
                                            return 1;
                                        }

                                        let x2 = a.last_name;
                                        let y2 = b.last_name;

                                        if (x2 < y2) {
                                            return -1;
                                        }

                                        if (x2 > y2) {
                                            return 1;
                                        }

                                        return 0;
                                    });
                                }

                                if (data[1] !== undefined) {
                                    data[1].sort(function (a, b) {
                                        let x1 = a.first_name;
                                        let y1 = b.first_name;

                                        if (x1 < y1) {
                                            return -1;
                                        }

                                        if (x1 > y1) {
                                            return 1;
                                        }

                                        let x2 = a.last_name;
                                        let y2 = b.last_name;

                                        if (x2 < y2) {
                                            return -1;
                                        }

                                        if (x2 > y2) {
                                            return 1;
                                        }

                                        return 0;
                                    });
                                }

                                if (data[2] !== undefined) {
                                    data[2].sort(function (a, b) {
                                        let x1 = a.first_name;
                                        let y1 = b.first_name;

                                        if (x1 < y1) {
                                            return -1;
                                        }

                                        if (x1 > y1) {
                                            return 1;
                                        }

                                        let x2 = a.last_name;
                                        let y2 = b.last_name;

                                        if (x2 < y2) {
                                            return -1;
                                        }

                                        if (x2 > y2) {
                                            return 1;
                                        }

                                        return 0;
                                    });
                                }

                                setStatePassTeacherTable(data[0]);
                                setStateFailTeacherTable(data[1]);
                                setStateUnknownTeacherTable(data[2]);
                            } else {
                                setStatePassTeacherTable(null);
                                setStateFailTeacherTable(null);
                                setStateUnknownTeacherTable(null);
                            }
                        });
                } else {
                    setStateStd3Table(null);
                }
            });

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleOnPerson(e) {
        setStateOnStdShow(false);
        setStateOnPersonShow(true);

        setStatePersonStd3Table(null);

        const msg = {
            command: "SELECT_TEACHER",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }     

                    setStatePersonTable(data);
                    setStateSelectedPerson(`${data[0].first_name} ${data[0].last_name}`);
                    setStateSelectedPersonID(data[0].id);

                    const msg = {
                        command: "SELECT_IDV",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            person_id: data[0].id,
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = parseInt(a.ts_id);
                                    let y1 = parseInt(b.ts_id);

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                //console.log(data);

                                setStatePersonStd3Table(data);

                                // std3Table = stateStd3Table;

                                // for (let i = 0 ; i < std3Table.lenght ; i++) {
                                //     std3Table[i].result = 0;
                                // }
                            } else {
                                setStatePersonStd3Table(null);
                            }
                        });
                } else {
                    setStatePersonTable(null);
                    setStateSelectedPerson(null);
                    setStateSelectedPersonID(null);
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleStd3Click(e) {
        setStateSelectedStd3("มาตรฐานสถานศึกษาที่ " + e.target.getAttribute("order"));
        setStateSelectedStd3ID(e.target.getAttribute("id"));
        setStateSelectedStd3Label(e.target.getAttribute("label"));

        setStatePassTeacherTable(null);
        setStateFailTeacherTable(null);
        setStateUnknownTeacherTable(null);

        const msg = {
            command: "SELECT_PASS_FAIL_UNKNOWN",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                std3_id: e.target.getAttribute("id")
            }
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_judgment.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    if (data[0] !== undefined) {
                        data[0].sort(function (a, b) {
                            let x1 = a.first_name;
                            let y1 = b.first_name;

                            if (x1 < y1) {
                                return -1;
                            }

                            if (x1 > y1) {
                                return 1;
                            }

                            let x2 = a.last_name;
                            let y2 = b.last_name;

                            if (x2 < y2) {
                                return -1;
                            }

                            if (x2 > y2) {
                                return 1;
                            }

                            return 0;
                        });
                    }

                    if (data[1] !== undefined) {
                        data[1].sort(function (a, b) {
                            let x1 = a.first_name;
                            let y1 = b.first_name;

                            if (x1 < y1) {
                                return -1;
                            }

                            if (x1 > y1) {
                                return 1;
                            }

                            let x2 = a.last_name;
                            let y2 = b.last_name;

                            if (x2 < y2) {
                                return -1;
                            }

                            if (x2 > y2) {
                                return 1;
                            }

                            return 0;
                        });
                    }

                    if (data[2] !== undefined) {
                        data[2].sort(function (a, b) {
                            let x1 = a.first_name;
                            let y1 = b.first_name;

                            if (x1 < y1) {
                                return -1;
                            }

                            if (x1 > y1) {
                                return 1;
                            }

                            let x2 = a.last_name;
                            let y2 = b.last_name;

                            if (x2 < y2) {
                                return -1;
                            }

                            if (x2 > y2) {
                                return 1;
                            }

                            return 0;
                        });
                    }

                    setStatePassTeacherTable(data[0]);
                    setStateFailTeacherTable(data[1]);
                    setStateUnknownTeacherTable(data[2]);
                } else {
                    setStatePassTeacherTable(null);
                    setStateFailTeacherTable(null);
                    setStateUnknownTeacherTable(null);
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let std3DropdownData = null;

    if (stateStd3Table) {
        std3DropdownData = stateStd3Table.map(function (object, i) {
            return <Dropdown.Item key={i} order={i + 1} id={object.id} label={object.name} onClick={handleStd3Click}>มาตรฐานสถานศึกษาที่ {i + 1}</Dropdown.Item>;
        });
    }

    let passTeachersTableData = null;

    if (statePassTeacherTable) {
        passTeachersTableData = statePassTeacherTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
            </tr>
        });

        passTeachersTableData =
            <>
                {/* <thead>
                    <tr>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ที่</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>คำนำหน้า</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ชื่อ</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>นามสกุล</td>
                    </tr>
                </thead> */}
                <tbody>
                    {passTeachersTableData}
                </tbody>
            </>;
    }

    let failTeachersTableData = null;

    if (stateFailTeacherTable) {
        failTeachersTableData = stateFailTeacherTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
            </tr>
        });

        failTeachersTableData =
            <>
                {/* <thead>
                    <tr>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ที่</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>คำนำหน้า</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ชื่อ</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>นามสกุล</td>
                    </tr>
                </thead> */}
                <tbody>
                    {failTeachersTableData}
                </tbody>
            </>;
    }

    let unknownTeachersTableData = null;

    if (stateUnknownTeacherTable) {
        unknownTeachersTableData = stateUnknownTeacherTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
            </tr>
        });

        unknownTeachersTableData =
            <>
                {/* <thead>
                    <tr>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ที่</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>คำนำหน้า</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>ชื่อ</td>
                        <td style={{ textAlign: "center", verticalAlign: "middle" }}>นามสกุล</td>
                    </tr>
                </thead> */}
                <tbody>
                    {unknownTeachersTableData}
                </tbody></>;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handlePersonClick(e) {
        setStateSelectedPerson(e.target.getAttribute("label"));
        setStateSelectedPersonID(e.target.getAttribute("id"));

        setStatePersonStd3Table(null);

        const msg = {
            command: "SELECT_IDV",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                person_id: e.target.getAttribute("id"),
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_judgment.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = parseInt(a.ts_id);
                        let y1 = parseInt(b.ts_id);

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    //console.log(data);

                    setStatePersonStd3Table(data);

                    // std3Table = stateStd3Table;

                    // for (let i = 0 ; i < std3Table.lenght ; i++) {
                    //     std3Table[i].result = 0;
                    // }
                } else {
                    setStatePersonStd3Table(null);
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let personDropdownData = null;

    if (statePersonTable) {
        personDropdownData = statePersonTable.map(function (object, i) {
            return <Dropdown.Item key={i} order={i + 1} label={`${object.first_name} ${object.last_name}`} id={object.id} onClick={handlePersonClick}>{`${object.first_name} ${object.last_name}`}</Dropdown.Item>;
        });
    }

    let personStd3TableData = null;

    if (statePersonStd3Table) {
        personStd3TableData = statePersonStd3Table.map(function (object, index) {
            //console.log(object.result);
            return <tr key={index}>
                <td style={{ textAlign: "center", verticalAlign: "top" }}>{index + 1}</td>
                <td style={{ textAlign: "left", verticalAlign: "top" }}>{object.name}<br />วันเวลาที่ประเมินล่าสุด : {object.datetime}</td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}><Form.Check id={object.id} type="checkbox" disabled={true} defaultChecked={object.result === "1" ? true : false} /></td>
            </tr>
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <Container fluid>
            <Row>
                <Col align="left">
                    <br />
                    <Card bg="light" text="#000000">
                        <Card.Header style={{ fontColor: "#000000" }}>
                            <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <Row>
                                    <Col sm="auto" style={{ paddingRight: "0px" }}><b>มาตรฐานที่ 3 กระบวนการจัดการเรียนการสอนที่เน้นผู้เรียนเป็นสำคัญ (ภาพรวม)</b></Col>
                                    <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body>
                            <Dropdown>
                                <Dropdown.Toggle variant="dark">เลือกโหมด</Dropdown.Toggle>
                                <Dropdown.Menu variant="dark">
                                    <Dropdown.Item href="#a1" onClick={handleOnStandard}>รายมาตรฐานสถานศึกษา</Dropdown.Item>
                                    <Dropdown.Item href="#a2" onClick={handleOnPerson}>รายบุคคล</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown><br />
                            {/* /////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                            <Dropdown hidden={!stateOnStdShow}>
                                <Dropdown.Toggle variant="dark">{stateSelectedStd3}</Dropdown.Toggle>
                                <Dropdown.Menu variant="dark">
                                    {std3DropdownData}
                                </Dropdown.Menu>
                            </Dropdown>
                            {/* /////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
                            <Dropdown hidden={!stateOnPersonShow}>
                                <Dropdown.Toggle variant="dark">{stateSelectedPerson}</Dropdown.Toggle>
                                <Dropdown.Menu variant="dark">
                                    {personDropdownData}
                                </Dropdown.Menu>
                            </Dropdown><br />
                            <Table striped bordered size="sm" variant="dark" hidden={!stateOnStdShow}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "left", verticalAlign: "middle" }}>{stateSelectedStd3Label}</td>
                                    </tr>
                                </thead>
                            </Table>
                            <Table striped bordered size="sm" variant="secondary" hidden={!stateOnStdShow}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}><b>ผู้ผ่านการประเมิน</b></td>
                                    </tr>
                                </thead>
                            </Table>
                            <Table striped bordered size="sm" variant="success" hidden={!stateOnStdShow}>
                                {passTeachersTableData}
                            </Table>
                            <Table striped bordered size="sm" variant="secondary" hidden={!stateOnStdShow}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}><b>ผู้ไม่ผ่านการประเมิน</b></td>
                                    </tr>
                                </thead>
                            </Table>
                            <Table striped bordered size="sm" variant="danger" hidden={!stateOnStdShow}>
                                {failTeachersTableData}
                            </Table>
                            <Table striped bordered size="sm" variant="secondary" hidden={!stateOnStdShow}>
                                <thead>
                                    <tr>
                                        <td style={{ textAlign: "center", verticalAlign: "middle" }}><b>ผู้ยังไม่ประเมิน</b></td>
                                    </tr>
                                </thead>
                            </Table>
                            <Table striped bordered size="sm" variant="warning" hidden={!stateOnStdShow}>
                                {unknownTeachersTableData}
                            </Table>
                            <Table striped bordered size="sm" hidden={!stateOnPersonShow}>
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>ที่</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>มาตรฐานสถานศึกษา</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>ผลการประเมิน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {personStd3TableData}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card><br />
                </Col>
            </Row>
        </Container >
    );
}

export default Std3Manager;