import React, { useState, useEffect, useRef } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Table, Card, Button, Modal, Form, Dropdown, Alert } from "react-bootstrap";
import { API } from "../../API";
global.Buffer = global.Buffer || require("buffer").Buffer;

const api = API();

function Admin10() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    let addErrorMsg = [];
    let editErrorMsg = [];

    const [stateAddModalShow, setStateAddModalShow] = useState(false);
    const [stateSelectedPrefix, setStateSelectedPrefix] = useState(null);
    const [statePrefixTable, setStatePrefixTable] = useState(null);
    const [stateSelectedType, setStateSelectedType] = useState(null);
    const [stateTypeTable, setStateTypeTable] = useState(null);
    const [stateSelectedState, setStateSelectedState] = useState(null);
    const [stateStateTable, setStateStateTable] = useState(null);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState(null);

    const [stateAddID, setStateAddID] = useState("");
    const [stateAddPrefixID, setStateAddPrefixID] = useState(null);
    const [stateAddFirstName, setStateAddFirstName] = useState("");
    const [stateAddLastName, setStateAddLastName] = useState("");
    const [stateAddEmail, setStateAddEmail] = useState("");
    const [stateAddTypeID, setStateAddTypeID] = useState(null);
    const [stateAddStateID, setStateAddStateID] = useState(null);

    let deleteModal = null;
    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateDeleteID, setStateDeleteID] = useState("");

    const [stateEditModalShow, setStateEditModalShow] = useState(false);
    const [stateEditID, setStateEditID] = useState("");
    const [stateEditCode, setStateEditCode] = useState("");
    const [stateEditPrefixID, setStateEditPrefixID] = useState(null);
    const [stateEditFirstName, setStateEditFirstName] = useState("");
    const [stateEditLastName, setStateEditLastName] = useState("");
    const [stateEditEmail, setStateEditEmail] = useState("");
    const [stateEditTypeID, setStateEditTypeID] = useState(null);
    const [stateEditStateID, setStateEditStateID] = useState(null);
    const [stateEditErrorShow, setStateEditErrorShow] = useState(false);
    const [stateEditErrorMsg, setStateEditErrorMsg] = useState(null);

    const [stateResetPasswordModalShow, setStateResetPasswordModalShow] = useState(false);

    const [stateTeacherTable, setStateTeacherTable] = useState(null);

    const teacherTableRef = useRef();

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    setStateTeacherTable(data);
                } else {
                    setStateTeacherTable(null);
                }
            });

        return () => {
        }
    }, []);

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddID("");
        setStateAddFirstName("");
        setStateAddLastName("");
        setStateAddEmail("");

        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        let msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        let json = JSON.stringify(msg);

        let options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_prefix.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = a.name.toLowerCase();
                        let y1 = b.name.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStatePrefixTable(data);
                    setStateAddPrefixID(data[0].id)
                    setStateSelectedPrefix(data[0].name);
                }
            });

        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        json = JSON.stringify(msg);

        options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_type.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = a.name.toLowerCase();
                        let y1 = b.name.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStateTypeTable(data);
                    setStateAddTypeID(data[0].id)
                    setStateSelectedType(data[0].name);
                }
            });

        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        json = JSON.stringify(msg);

        options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher_state.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    data.sort(function (a, b) {
                        let x1 = a.name.toLowerCase();
                        let y1 = b.name.toLowerCase();

                        if (x1 < y1) {
                            return -1;
                        }

                        if (x1 > y1) {
                            return 1;
                        }

                        return 0;
                    });

                    setStateStateTable(data);
                    setStateAddStateID(data[0].id)
                    setStateSelectedState(data[0].name);
                }
            });

        ///////////////////////////////////////////////////////////////////////////////////////////////////////

        setStateAddModalShow(true);
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
        setStateAddErrorShow(false);
    }

    function handleAddIDChange(e) {
        const re = /^[0-9\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            setStateAddID(e.target.value);
        }
    }

    function handlePrefixClick(e) {
        e.preventDefault();

        setStateAddPrefixID(e.target.getAttribute("id"));
        setStateEditPrefixID(e.target.getAttribute("id"));
        setStateSelectedPrefix(e.target.getAttribute("prefix"));
    }

    function handleAddFirstNameChange(e) {
        setStateAddFirstName(e.target.value);
    }

    function handleAddLastNameChange(e) {
        setStateAddLastName(e.target.value);
    }

    function handleAddEmailChange(e) {
        setStateAddEmail(e.target.value);
    }

    function handleTypeClick(e) {
        e.preventDefault();

        setStateAddTypeID(e.target.getAttribute("id"));
        setStateEditTypeID(e.target.getAttribute("id"));
        setStateSelectedType(e.target.getAttribute("type"));
    }

    function handleStateClick(e) {
        e.preventDefault();

        setStateAddStateID(e.target.getAttribute("id"));
        setStateEditStateID(e.target.getAttribute("id"));
        setStateSelectedState(e.target.getAttribute("state"));
    }

    function handleAdd(e) {
        e.preventDefault();

        if (stateAddID.length === 13 && stateAddFirstName.length > 0 && stateAddLastName.length > 0 &&
            stateAddEmail.length > 0) {

            setStateAddErrorShow(false);
            let md5 = require("md5");

            const msg = {
                command: "INSERT",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    id: stateAddID,
                    password: md5(stateAddID),
                    prefix_id: stateAddPrefixID,
                    first_name: stateAddFirstName,
                    last_name: stateAddLastName,
                    email: stateAddEmail,
                    type_id: stateAddTypeID,
                    state_id: stateAddStateID
                }
            };

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            //console.log(json);

            api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    //console.log(data);

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: ""
                        };

                        const json = JSON.stringify(msg);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];
                                    }                

                                    setStateTeacherTable(data);
                                } else {
                                    setStateTeacherTable(null);
                                }
                            });

                        setStateAddErrorShow(false);
                        setStateAddModalShow(false);
                    } else {
                        addErrorMsg = [];
                        setStateAddErrorShow(true);

                        addErrorMsg.push("เลขประจำตัวประชาชนซ้ำกัน");

                        setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            addErrorMsg = [];
            setStateAddErrorShow(true);

            if (stateAddID.length !== 13) {
                addErrorMsg.push("เลขประจำตัวประชาชนจะต้องมี 13 หลัก");
            }

            if (stateAddFirstName.length === 0) {
                addErrorMsg.push("กรุณากรอกชื่อ");
            }

            if (stateAddLastName.length === 0) {
                addErrorMsg.push("กรุณากรอกนามสกุล");
            }

            if (stateAddEmail.length === 0) {
                addErrorMsg.push("กรุณากรอกอีเมล์");
            }

            setStateAddErrorMsg(addErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteModalShow(true);
        setStateDeleteID(e.target.getAttribute("id"));
    }

    function handleDelete(e) {
        e.preventDefault();

        //console.log(e.target.getAttribute("id"));

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateDeleteID
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    //console.log(data[0] );

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0] );

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }     

                                setStateTeacherTable(data);
                                setStateDeleteModalShow(false);
                            } else {
                                setStateTeacherTable(null);
                            }
                        });
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {
        e.preventDefault();

        const msg = {
            command: "SELECT_IND",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: e.target.getAttribute("id")
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let mData = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (mData[0] !== "fail") {
                    //////////////////////////////////////////////////////////////////////////////////////////

                    let msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    let json = JSON.stringify(msg);

                    let options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_prefix.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStatePrefixTable(data);
                                setStateEditPrefixID(mData[0].prefix_id)
                                setStateSelectedPrefix(mData[0].prefix);
                            }
                        });

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    json = JSON.stringify(msg);

                    options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_type.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateTypeTable(data);
                                setStateEditTypeID(mData[0].type_id)
                                setStateSelectedType(mData[0].type);
                            }
                        });

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    json = JSON.stringify(msg);

                    options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher_state.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            if (data[0] !== "fail") {
                                data.sort(function (a, b) {
                                    let x1 = a.name.toLowerCase();
                                    let y1 = b.name.toLowerCase();

                                    if (x1 < y1) {
                                        return -1;
                                    }

                                    if (x1 > y1) {
                                        return 1;
                                    }

                                    return 0;
                                });

                                setStateStateTable(data);
                                setStateEditStateID(mData[0].state_id)
                                setStateSelectedState(mData[0].state);
                            }
                        });

                    ///////////////////////////////////////////////////////////////////////////////////////////////////////

                    setStateEditID(mData[0].id);
                    setStateEditCode(mData[0].code);
                    setStateEditFirstName(mData[0].first_name);
                    setStateEditLastName(mData[0].last_name);
                    setStateEditEmail(mData[0].email);
                    setStateEditModalShow(true);
                } else {

                }
            });
    }

    function handleEditModalHide(e) {
        setStateEditModalShow(false);
        setStateEditErrorShow(false);
    }

    function handleEditCodeChange(e) {
        setStateEditCode(e.target.value);
    }

    function handleEditFirstNameChange(e) {
        setStateEditFirstName(e.target.value);
    }

    function handleEditLastNameChange(e) {
        setStateEditLastName(e.target.value);
    }

    function handleEditEmailChange(e) {
        setStateEditEmail(e.target.value);
    }

    function handleEdit(e) {
        e.preventDefault();

        if (stateEditID.length === 13 && stateEditFirstName.length > 0 && stateEditLastName.length > 0 &&
            stateEditEmail.length > 0) {

            setStateAddErrorShow(false);

            const msg = {
                command: "UPDATE",
                id: reduxState.userID,
                password: reduxState.userPassword,
                data: {
                    id: stateEditID,
                    code: stateEditCode,
                    prefix_id: stateEditPrefixID,
                    first_name: stateEditFirstName,
                    last_name: stateEditLastName,
                    email: stateEditEmail,
                    type_id: stateEditTypeID,
                    state_id: stateEditStateID
                }
            };

            const json = JSON.stringify(msg);

            const options = {
                header: {
                    "content-type": "text/plain"
                }
            };

            //console.log(json);

            api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                .then(res => {
                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                    //console.log(data);

                    if (data[0] === "pass") {
                        const msg = {
                            command: "SELECT",
                            id: reduxState.userID,
                            password: reduxState.userPassword,
                            data: ""
                        };

                        const json = JSON.stringify(msg);

                        const options = {
                            header: {
                                "content-type": "text/plain"
                            }
                        };

                        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                            .then(res => {
                                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                if (data[0] !== "fail") {
                                    for (let i = 0; i < data.length; i++) {
                                        data[i] = data[i][0];
                                    }     

                                    setStateTeacherTable(data);
                                } else {
                                    setStateTeacherTable(null);
                                }
                            });

                        setStateEditErrorShow(false);
                        setStateEditModalShow(false);
                    } else {
                        editErrorMsg = [];
                        setStateEditErrorShow(true);

                        editErrorMsg.push("การแก้ไขข้อมูลผิดพลาด");

                        setStateEditErrorMsg(editErrorMsg.map(function (object, index) {
                            return <div key={index}>{`- `}{object}</div>;
                        }));
                    }
                });
        } else {
            editErrorMsg = [];
            setStateEditErrorShow(true);

            if (stateEditID.length !== 13) {
                editErrorMsg.push("เลขประจำตัวประชาชนจะต้องมี 13 หลัก");
            }

            if (stateEditFirstName.length === 0) {
                editErrorMsg.push("กรุณากรอกชื่อ");
            }

            if (stateEditLastName.length === 0) {
                editErrorMsg.push("กรุณากรอกนามสกุล");
            }

            if (stateEditEmail.length === 0) {
                editErrorMsg.push("กรุณากรอกอีเมล์");
            }

            setStateEditErrorMsg(editErrorMsg.map(function (object, index) {
                return <div key={index}>{`- `}{object}</div>;
            }));
        }
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleResetPassword(e) {
        e.preventDefault();

        const msg = {
            command: "RESET_PASSWORD",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: e.target.getAttribute("id")
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    //console.log(data[0] );

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: ""
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_teacher.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data[0] );

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }     

                                setStateTeacherTable(data);

                                setStateResetPasswordModalShow(true);
                            } else {
                                setStateTeacherTable(null);
                            }
                        });
                }
            });
    }

    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////
    /////////////////////////////////////////////////////////////////////////////////////////////////////////

    let prefixDropdownData = null;
    let typeDropdownData = null;
    let stateDropdownData = null;

    if (statePrefixTable) {
        prefixDropdownData = statePrefixTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} prefix={object.name} onClick={handlePrefixClick}>{object.name}</Dropdown.Item>;
        });
    }

    if (stateTypeTable) {
        typeDropdownData = stateTypeTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} type={object.name} onClick={handleTypeClick}>{object.name}</Dropdown.Item>;
        });
    }

    if (stateStateTable) {
        stateDropdownData = stateStateTable.map(function (object) {
            return <Dropdown.Item key={object.id} id={object.id} state={object.name} onClick={handleStateClick}>{object.name}</Dropdown.Item>;
        });
    }

    let teachersTableData = null;

    if (stateTeacherTable) {
        teachersTableData = stateTeacherTable.map(function (object, index) {
            let buttonDisable = (object.id === reduxState.userID);

            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.id}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.code}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>{object.email}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.type}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.state}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF" disabled={buttonDisable}>ลบ</Button></div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="success" size="sm" onClick={handleEditClick} text="#FFFFFF" disabled={buttonDisable}>แก้ไข</Button></div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="warning" size="sm" onClick={handleResetPassword} text="#FFFFFF" disabled={buttonDisable}>รีเซ็ตรหัสผ่าน</Button></div></td>
            </tr>
        });
    }

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}><b>เพิ่ม/ลบ/แก้ไข ผู้ใช้</b></Card.Header>
                <Card.Body>
                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่ม</Button>
                    <br />
                    <br />
                    <div>
                        <Table striped bordered size="sm" responsive="xxl">
                            <thead>
                                <tr>
                                    <th><div align="center">ที่</div></th>
                                    <th><div align="center">เลขประจำตัวฯ</div></th>
                                    <th><div align="center">รหัส</div></th>
                                    <th><div align="center">คำนำหน้า</div></th>
                                    <th><div align="center">ชื่อ</div></th>
                                    <th><div align="center">นามสกุล</div></th>
                                    <th><div align="center">อีเมล์</div></th>
                                    <th><div align="center">ประเภท</div></th>
                                    <th><div align="center">สถานะ</div></th>
                                    <th><div align="center"></div></th>
                                    <th><div align="center"></div></th>
                                    <th><div align="center"></div></th>
                                </tr>
                            </thead>
                            <tbody>
                                {teachersTableData}
                            </tbody>
                        </Table>
                    </div>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกข้อมูลผู้ใช้</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddID} onChange={handleAddIDChange} placeholder="เลขประจำตัวประชาชน" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    คำนำหน้า :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedPrefix}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {prefixDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddFirstName} onChange={handleAddFirstNameChange} placeholder="ชื่อ" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddLastName} onChange={handleAddLastNameChange} placeholder="นามสกุล" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateAddEmail} onChange={handleAddEmailChange} placeholder="อีเมล์" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    ประเภท : <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedType}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {typeDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    สถานะ : <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedState}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {stateDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleAdd} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateEditModalShow} onHide={handleEditModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณาแก้ไขข้อมูลผู้ใช้</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateEditErrorShow}>{stateEditErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditID} placeholder="เลขประจำตัวประชาชน" disabled />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditCode} placeholder="รหัส" onChange={handleEditCodeChange}/>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    คำนำหน้า :
                                    <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedPrefix}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {prefixDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditFirstName} onChange={handleEditFirstNameChange} placeholder="ชื่อ" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditLastName} onChange={handleEditLastNameChange} placeholder="นามสกุล" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Form.Control type="input" value={stateEditEmail} onChange={handleEditEmailChange} placeholder="อีเมล์" />
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    ประเภท : <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedType}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {typeDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    สถานะ : <Dropdown>
                                        <Dropdown.Toggle variant="light">{stateSelectedState}</Dropdown.Toggle>
                                        <Dropdown.Menu variant="light">
                                            {stateDropdownData}
                                        </Dropdown.Menu>
                                    </Dropdown>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="success" onClick={handleEdit} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateResetPasswordModalShow} onHide={() => setStateResetPasswordModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>รีเซ็ตรหัสผ่านแล้ว</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="success" onClick={() => setStateResetPasswordModalShow(false)} size="md">
                        ตกลง
                    </Button>
                </Modal.Footer>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบผู้ใช้</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
        </div >
    );
}

export default Admin10;