import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Dropdown, Table, Button, Card, Row, Col, Container, ButtonToolbar, Modal, Form, Alert, InputGroup } from "react-bootstrap";
import { API } from "../../API";

global.Buffer = global.Buffer || require("buffer").Buffer

const api = API();

function Admin6() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState("1");

    const [stateClassroomsTable, setStateClassroomsTable] = useState(null);
    const [stateLevelsTable, setStateLevelsTable] = useState(null);
    const [stateRoomsTable, setStateRoomsTable] = useState(null);
    const [stateSelectedLevel, setStateSelectedLevel] = useState("1");
    const [stateSelectedRoom, setStateSelectedRoom] = useState("1");

    const [stateStudentsTable, setStateStudentsTable] = useState(null);

    const [stateAddID, setStateAddID] = useState(null);
    const [stateAddErrorShow, setStateAddErrorShow] = useState(false);
    const [stateAddErrorMsg, setStateAddErrorMsg] = useState(null);
    const [stateAddModalShow, setStateAddModalShow] = useState(false);

    const [stateAddStudentsTable, setStateAddStudentsTable] = useState(null);

    const [stateDeleteID, setStateDeleteID] = useState(null);
    const [stateDeleteModalShow, setStateDeleteModalShow] = useState(false);
    const [stateConfirmAddModalShow, setStateConfirmAddModalShow] = useState(false);


    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    let l_edu_years_id = data[0].edu_years_id;
                    let l_edu_term = data[0].edu_term;

                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: data[0].edu_years_id,
                            edu_term: data[0].edu_term
                        }
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_classroom.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                let level = [];

                                for (let i = 0; i < data.length; i++) {
                                    let insert = true;

                                    for (let j = 0; j < level.length; j++) {
                                        if (level[j].level === data[i].level) {
                                            insert = false;
                                        }
                                    }

                                    if (insert) {
                                        level.push({ level: data[i].level });
                                    }
                                }

                                //console.log(level);

                                setStateLevelsTable(level);
                                setStateSelectedLevel(level[0].level);

                                const roomTemp = data.filter(item => item.level === level[0].level);
                                //console.log(rooms);

                                let room = [];

                                for (let i = 0; i < roomTemp.length; i++) {
                                    room.push({ room: roomTemp[i].room });
                                }

                                setStateRoomsTable(room);
                                setStateSelectedRoom(room[0].room);

                                const msg = {
                                    command: "SELECT_BY_CLASSROOM",
                                    id: reduxState.userID,
                                    password: reduxState.userPassword,
                                    data: {
                                        edu_year_id: l_edu_years_id,
                                        edu_term: l_edu_term,
                                        level: level[0].level,
                                        room: room[0].room
                                    }
                                };

                                const json = JSON.stringify(msg);

                                //console.log(json);

                                const options = {
                                    header: {
                                        "content-type": "text/plain"
                                    }
                                };

                                api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                                    .then(res => {
                                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                        //console.log(data);

                                        if (data[0] !== "fail") {
                                            for (let i = 0; i < data.length; i++) {
                                                data[i] = data[i][0];

                                                data[i].email = data[i].email.replace(/\s/g, "");
                                            }

                                            setStateStudentsTable(null);
                                            setStateStudentsTable(data);
                                        } else {
                                            setStateStudentsTable(null);
                                        }
                                    });

                                setStateClassroomsTable(data);
                            } else {
                                setStateLevelsTable(null);
                                setStateSelectedLevel(null);
                                setStateRoomsTable(null);
                                setStateSelectedRoom(null);
                                setStateClassroomsTable(null);
                            }
                        });
                }
            });


        return () => {
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleLevelClick(e) {
        setStateSelectedLevel(e.target.getAttribute("level"));

        const roomTemp = stateClassroomsTable.filter(item => item.level === e.target.getAttribute("level"));
        //console.log(rooms);

        let room = [];

        for (let i = 0; i < roomTemp.length; i++) {
            room.push({ room: roomTemp[i].room });
        }

        setStateRoomsTable(room);
        setStateSelectedRoom(room[0].room);

        const msg = {
            command: "SELECT_BY_CLASSROOM",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm,
                level: e.target.getAttribute("level"),
                room: room[0].room
            }
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];

                        data[i].email = data[i].email.replace(/\s/g, "");
                    }

                    setStateStudentsTable(null);
                    setStateStudentsTable(data);
                } else {
                    setStateStudentsTable(null);
                }
            });
    }

    function handleRoomClick(e) {
        setStateSelectedRoom(e.target.getAttribute("room"));

        const msg = {
            command: "SELECT_BY_CLASSROOM",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm,
                level: stateSelectedLevel,
                room: e.target.getAttribute("room")
            }
        };

        const json = JSON.stringify(msg);

        //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];

                        data[i].email = data[i].email.replace(/\s/g, "");
                    }

                    setStateStudentsTable(null);
                    setStateStudentsTable(data);
                } else {
                    setStateStudentsTable(null);
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleAddClick(e) {
        e.preventDefault();

        setStateAddModalShow(true);
        setStateAddErrorShow(false);
        setStateAddStudentsTable(null);
        setStateAddID("");
    }

    function handleAddModalHide(e) {
        setStateAddModalShow(false);
    }

    function handleAddIDChange(e) {
        const re = /^[0-9\b]+$/;

        if (e.target.value === "" || re.test(e.target.value)) {
            if (e.target.value.length <= 5) {
                setStateAddID(e.target.value);
            }
        }
    }

    function handleSearchClick(e) {
        e.preventDefault();

        const msg = {
            command: "SELECT_IDV",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                s_id: stateAddID,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student_has_classroom.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    data[0] = data[0][0];

                    setStateAddStudentsTable(null);
                    setStateAddStudentsTable(data);
                } else {
                    setStateAddStudentsTable(null);
                }
            });
    }

    function handleAdd(e) {
        e.preventDefault();

        const msg = {
            command: "INSERT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                s_id: stateAddID,
                level: stateSelectedLevel,
                room: stateSelectedRoom,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student_has_classroom.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT_BY_CLASSROOM",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm,
                            level: stateSelectedLevel,
                            room: stateSelectedRoom
                        }
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];

                                    data[i].email = data[i].email.replace(/\s/g, "");
                                }

                                setStateStudentsTable(null);
                                setStateStudentsTable(data);

                                setStateAddModalShow(false);
                            } else {
                                setStateStudentsTable(null);
                            }
                        });
                } else {
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleDeleteClick(e) {
        e.preventDefault();

        setStateDeleteModalShow(true);
        setStateDeleteID(e.target.getAttribute("id"));
    }


    function handleDelete(e) {
        e.preventDefault();

        // console.log(`s_id: ${e.target.getAttribute("id")}, level: ${stateSelectedLevel}, room: ${stateSelectedRoom}, ` + 
        // `edu_year_id: ${stateSelectedEduYearId}, edu_term: ${stateSelectedEduTerm}`);

        const msg = {
            command: "DELETE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                s_id: stateDeleteID,
                level: stateSelectedLevel,
                room: stateSelectedRoom,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("admin_student_has_classroom.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT_BY_CLASSROOM",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm,
                            level: stateSelectedLevel,
                            room: stateSelectedRoom
                        }
                    };

                    const json = JSON.stringify(msg);

                    //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("admin_student.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];

                                    data[i].email = data[i].email.replace(/\s/g, "");
                                }

                                setStateStudentsTable(null);
                                setStateStudentsTable(data);

                                setStateDeleteModalShow(false);
                            } else {
                                setStateStudentsTable(null);
                            }
                        });
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {

    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let levelDropdownData = null;

    if (stateLevelsTable) {
        levelDropdownData = stateLevelsTable.map(function (object, i) {
            return <Dropdown.Item key={i} level={object.level} onClick={handleLevelClick}>{`ชั้นมัธยมศึกษาปีที่ ${object.level}`}</Dropdown.Item>;
        });
    }

    let roomDropdownData = null;

    if (stateRoomsTable) {
        roomDropdownData = stateRoomsTable.map(function (object, i) {
            return <Dropdown.Item key={i} room={object.room} onClick={handleRoomClick}>{`ห้อง ${object.room}`}</Dropdown.Item>;
        });
    }

    let studentsTableData = null;

    if (stateStudentsTable) {
        studentsTableData = stateStudentsTable.map(function (object, index) {
            return <tr key={index}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{index + 1}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.id}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
                <td><div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "150px" }}>{object.email}</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="danger" size="sm" onClick={handleDeleteClick} text="#FFFFFF">ลบ</Button></div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}><Button id={object.id} variant="success" size="sm" onClick={handleEditClick} text="#FFFFFF" disabled>แก้ไข</Button></div></td>
            </tr>
        });
    }

    let addStudentsTableData = null;

    if (stateAddStudentsTable) {
        addStudentsTableData = stateAddStudentsTable.map(function (object, index) {
            return (
            <>
                <tr key={index}>
                    <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.id}</div></td>
                    <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.prefix}</div></td>
                    <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.first_name}</div></td>
                    <td><div align="left" style={{ whiteSpace: "nowrap" }}>{object.last_name}</div></td>
                    <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.level === "0" ? "-" : object.level}</div></td>
                    <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object.room ===  "0" ? "-" : object.room}</div></td>
                </tr>
                {object.level === "0" ?
                    <tr key={index + 1}>
                        <td colSpan={6}>
                            <div align="center">
                                <Button variant="primary" id={object.id} onClick={handleAdd} size="md">
                                    ยืนยันการเพิ่มนักเรียน
                                </Button>
                            </div>
                        </td>
                    </tr>
                    :
                    null
                }
            </>);
        });
    } else {
        addStudentsTableData =
            <tr key={1}>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
                <td><div align="center" style={{ whiteSpace: "nowrap" }}>-</div></td>
            </tr>;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <div>
            <Card bg="light" text="#000000">
                <Card.Header style={{ fontColor: "#000000" }}>
                    <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                        <Row>
                            <Col sm="auto" style={{ paddingRight: "0px" }}><b>เพิ่ม/ลบ/แก้ไข นักเรียนในห้องเรียน</b></Col>
                            <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                        </Row>
                    </Container>
                </Card.Header>
                <Card.Body>
                    <Table size="sm" borderless={true}>
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <ButtonToolbar>
                                        <Dropdown>
                                            <Dropdown.Toggle variant="dark">{`ชั้นมัธยมศึกษาปีที่ ${stateSelectedLevel}`}</Dropdown.Toggle>
                                            <Dropdown.Menu variant="dark">
                                                {levelDropdownData}
                                            </Dropdown.Menu>
                                        </Dropdown>&nbsp;&nbsp;
                                        <Dropdown>
                                            <Dropdown.Toggle variant="dark">{`ห้อง ${stateSelectedRoom}`}</Dropdown.Toggle>
                                            <Dropdown.Menu variant="dark">
                                                {roomDropdownData}
                                            </Dropdown.Menu>
                                        </Dropdown>
                                    </ButtonToolbar>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Button variant="success" size="md" onClick={handleAddClick} text="#FFFFFF">เพิ่มนักเรียนเข้าห้องเรียน</Button>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table striped bordered size="sm" responsive="xxl">
                        <thead>
                            <tr>
                                <th><div align="center">ที่</div></th>
                                <th><div align="center">เลขประจำตัวฯ</div></th>
                                <th><div align="center">คำนำหน้า</div></th>
                                <th><div align="center">ชื่อ</div></th>
                                <th><div align="center">นามสกุล</div></th>
                                <th><div align="center">อีเมล์</div></th>
                                <th><div align="center"></div></th>
                                <th><div align="center"></div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {studentsTableData}
                        </tbody>
                    </Table>
                </Card.Body>
            </Card>
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateAddModalShow} onHide={handleAddModalHide} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>กรุณากรอกเลขประจำตัวนักเรียน</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Table borderless={true} size="sm" responsive="sm">
                        <tbody>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <Alert variant="danger" show={stateAddErrorShow}>{stateAddErrorMsg}</Alert>
                                </td>
                            </tr>
                            <tr>
                                <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                    <InputGroup>
                                        <Form.Control type="input" value={stateAddID} onChange={handleAddIDChange} placeholder="เลขประจำตัวนักเรียน" />
                                        <Button variant="success" onClick={handleSearchClick}>ค้นหา</Button>
                                    </InputGroup>
                                </td>
                            </tr>
                        </tbody>
                    </Table>
                    <Table striped bordered size="sm" responsive="xxl">
                        <thead>
                            <tr>
                                <th><div align="center">เลขประจำตัวฯ</div></th>
                                <th><div align="center">คำนำหน้า</div></th>
                                <th><div align="center">ชื่อ</div></th>
                                <th><div align="center">นามสกุล</div></th>
                                <th><div align="center">ชั้น ม.</div></th>
                                <th><div align="center">ห้อง</div></th>
                            </tr>
                        </thead>
                        <tbody>
                            {addStudentsTableData}
                        </tbody>
                    </Table>
                </Modal.Body>
            </Modal >
            {/* /////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
/////////////////////////////////////////////////////////////////////////////////////////////////////////////
///////////////////////////////////////////////////////////////////////////////////////////////////////////// */}
            <Modal show={stateDeleteModalShow} onHide={() => setStateDeleteModalShow(false)} backdrop="static" keyboard={false}
                style={{ fontFamily: "Sarabun" }} centered size="md">
                <Modal.Header closeButton>
                    <Modal.Title>ยืนยันการลบนักเรียน</Modal.Title>
                </Modal.Header>
                <Modal.Footer>
                    <Button variant="danger" onClick={handleDelete} size="md">
                        ตกลง
                    </Button>
                    <Button variant="success" onClick={() => setStateDeleteModalShow(false)} size="md">
                        ยกเลิก
                    </Button>
                </Modal.Footer>
            </Modal >
        </div>
    );
}

export default Admin6;