import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Dropdown, Table, Form, Button } from "react-bootstrap";
import { API } from "../API";

const api = API();

// function Std1Teacher() {

//     useEffect(() => {

//         return () => {
//         }
//     }, []);

//     return (
//         <Container fluid>
//             <Row>
//                 <Col align="left">
//                     <br />
//                     <Card bg="light" text="#000000">
//                         <Card.Header style={{ fontColor: "#000000" }}>
//                             <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
//                                 <Row>
//                                     <Col sm="auto" style={{ paddingRight: "0px" }}><b>มาตรฐานที่ 1 คุณภาพของผู้เรียน (กำลังพัฒนา)</b></Col>
//                                 </Row>
//                             </Container>
//                         </Card.Header>
//                     </Card>
//                 </Col>
//             </Row>
//         </Container>
//     );
// }

// export default Std1Teacher;

function Std1Teacher() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);

    const [statePersonWorkTable, setStatePersonWorkTable] = useState(null);
    const [stateSelectedPersonWorkLabel, setStateSelectedPersonWorkLabel] = useState(null);
    const [stateSelectedPersonWorkId, setStateSelectedPersonWorkId] = useState(null);

    const [stateSelectedWorkStd1Table, setStateSelectedWorkStd1Table] = useState(null);

    const [stateSelectedWorkStudentStd1Table, setStateSelectedWorkStudentStd1Table] = useState(null);

    const [stateCheckEnable, setStateCheckEnable] = useState(false);
    const [stateEditButtonShow, setStateEditButtonShow] = useState(true);
    const [stateConfirmButtonShow, setStateConfirmButtonShow] = useState(false);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("user_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    let eduYearsId = data[0].edu_years_id;
                    let eduTerm = data[0].edu_term;

                    setStateCheckEnable(false);
                    setStateEditButtonShow(true);
                    setStateConfirmButtonShow(false);

                    const msg = {
                        command: "SELECT_IDV",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            person_id: reduxState.userID,
                            edu_year_id: eduYearsId,
                            edu_term: eduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    // //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("user_work.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                setStatePersonWorkTable(null);
                                setStatePersonWorkTable(data);

                                setStateSelectedPersonWorkLabel(`${data[0].subject_code} ${data[0].subject_name} ม.${data[0].level}/${data[0].room}`);
                                setStateSelectedPersonWorkId(data[0].id);

                                let work_id = data[0].id;

                                const msg = {
                                    command: "SELECT_STD_BY_IDV_WORK",
                                    id: reduxState.userID,
                                    password: reduxState.userPassword,
                                    data: {
                                        work_id: work_id,
                                        edu_year_id: eduYearsId,
                                        edu_term: eduTerm
                                    }
                                };

                                const json = JSON.stringify(msg);

                                // //console.log(json);

                                const options = {
                                    header: {
                                        "content-type": "text/plain"
                                    }
                                };

                                api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
                                    .then(res => {
                                        let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                        //console.log(data);

                                        if (data[0] !== "fail") {
                                            for (let i = 0; i < data.length; i++) {
                                                data[i] = data[i][0];
                                            }

                                            setStateSelectedWorkStd1Table(null);
                                            setStateSelectedWorkStd1Table(data);
                                            let numStd1 = data.length;

                                            const msg = {
                                                command: "SELECT_STU_JUDG_BY_IDV_WORK",
                                                id: reduxState.userID,
                                                password: reduxState.userPassword,
                                                data: {
                                                    work_id: work_id,
                                                    edu_year_id: eduYearsId,
                                                    edu_term: eduTerm
                                                }
                                            };

                                            const json = JSON.stringify(msg);

                                            // //console.log(json);

                                            const options = {
                                                header: {
                                                    "content-type": "text/plain"
                                                }
                                            };

                                            api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
                                                .then(res => {
                                                    let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                                                    //console.log(data);

                                                    if (data[0] !== "fail") {
                                                        for (let i = 0; i < data.length; i++) {
                                                            data[i] = data[i][0];
                                                        }

                                                        let array = [];
                                                        let offset = numStd1; // 3; // 3

                                                        for (let i = 0; i < data.length; i = i + offset) {
                                                            let element = [];
                                                            element.push(data[i].prefix); // 0
                                                            element.push(data[i].first_name); // 1
                                                            element.push(data[i].last_name); // 2
                                                            element.push(data[i].id); // 3
                                                            element.push(data[i].result); // 4
                                                            element.push(data[i].datetime); // 5
                                                            array.push(element);
                                                        }

                                                        for (let i = 0; i < array.length; i++) {
                                                            for (let j = 1; j < offset; j++) {
                                                                array[i].push(data[i * offset + j].id); // 6
                                                                array[i].push(data[i * offset + j].result); // 7
                                                                array[i].push(data[i * offset + j].datetime); // 8
                                                            }
                                                        }

                                                        // for (let i = 0; i < array.length; i++) {
                                                        //     array[i].push(data[i * offset + 1].id); // 6
                                                        //     array[i].push(data[i * offset + 1].result); // 7
                                                        //     array[i].push(data[i * offset + 1].datetime); // 8
                                                        //     array[i].push(data[i * offset + 2].id); // 9
                                                        //     array[i].push(data[i * offset + 2].result); // 10
                                                        //     array[i].push(data[i * offset + 2].datetime); // 11
                                                        // }

                                                        setStateSelectedWorkStudentStd1Table(null);
                                                        setStateSelectedWorkStudentStd1Table(array);
                                                    } else {
                                                        setStateSelectedWorkStudentStd1Table(null);
                                                    }
                                                });

                                        } else {
                                            setStateSelectedWorkStd1Table(null);
                                        }
                                    });
                            } else {
                                setStatePersonWorkTable(null);
                            }
                        });
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handlePersonWorkClick(e) {
        setStateCheckEnable(false);
        setStateEditButtonShow(true);
        setStateConfirmButtonShow(false);

        setStateSelectedPersonWorkLabel(e.target.getAttribute("label"));
        setStateSelectedPersonWorkId(e.target.getAttribute("id"));
        setStateSelectedWorkStudentStd1Table(null);

        // console.log(stateSelectedPersonID);
        // console.log(e.target.getAttribute("id"));

        let work_id = e.target.getAttribute("id");

        const msg = {
            command: "SELECT_STD_BY_IDV_WORK",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: {
                work_id: work_id,
                edu_year_id: stateSelectedEduYearId,
                edu_term: stateSelectedEduTerm
            }
        };

        const json = JSON.stringify(msg);

        // //console.log(json);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    for (let i = 0; i < data.length; i++) {
                        data[i] = data[i][0];
                    }

                    setStateSelectedWorkStd1Table(null);
                    setStateSelectedWorkStd1Table(data);
                    let numStd1 = data.length;

                    const msg = {
                        command: "SELECT_STU_JUDG_BY_IDV_WORK",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            work_id: work_id,
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    // //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                let array = [];
                                let offset = numStd1; // 3

                                for (let i = 0; i < data.length; i = i + offset) {
                                    let element = [];
                                    element.push(data[i].prefix); // 0
                                    element.push(data[i].first_name); // 1
                                    element.push(data[i].last_name); // 2
                                    element.push(data[i].id); // 3
                                    element.push(data[i].result); // 4
                                    element.push(data[i].datetime); // 5
                                    array.push(element);
                                }

                                for (let i = 0; i < array.length; i++) {
                                    for (let j = 1; j < offset; j++) {
                                        array[i].push(data[i * offset + j].id); // 6
                                        array[i].push(data[i * offset + j].result); // 7
                                        array[i].push(data[i * offset + j].datetime); // 8
                                    }
                                }

                                // for (let i = 0; i < array.length; i++) {
                                //     array[i].push(data[i * offset + 1].id); // 6
                                //     array[i].push(data[i * offset + 1].result); // 7
                                //     array[i].push(data[i * offset + 1].datetime); // 8
                                //     array[i].push(data[i * offset + 2].id); // 9
                                //     array[i].push(data[i * offset + 2].result); // 10
                                //     array[i].push(data[i * offset + 2].datetime); // 11
                                // }

                                setStateSelectedWorkStudentStd1Table(null);
                                setStateSelectedWorkStudentStd1Table(array);
                            } else {
                                setStateSelectedWorkStudentStd1Table(null);
                            }
                        });

                } else {
                    setStateSelectedWorkStd1Table(null);
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let personWorkDropdownData = null;

    if (statePersonWorkTable) {
        personWorkDropdownData = statePersonWorkTable.map(function (object, i) {
            return <Dropdown.Item key={i} order={i + 1} label={`${object.subject_code} ${object.subject_name} ม.${object.level}/${object.room}`} id={object.id} onClick={handlePersonWorkClick}>{`${object.subject_code} ${object.subject_name} ม.${object.level}/${object.room}`}</Dropdown.Item>;
        });
    }

    let selectedWorkStd1Data = null;

    if (stateSelectedWorkStd1Table) {
        selectedWorkStd1Data = stateSelectedWorkStd1Table.map(function (object, i) {
            return <tr key={i}><td>{`มฐ.${i + 1} ${object.ss_name}`}</td></tr>;
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {
        setStateCheckEnable(true);
        setStateEditButtonShow(false);
        setStateConfirmButtonShow(true);
    }

    function handleConfirmClick(e) {
        setStateCheckEnable(false);
        setStateEditButtonShow(true);
        setStateConfirmButtonShow(false);

        const msg = {
            command: "UPDATE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateSelectedWorkStudentStd1Table,
            num_std: stateSelectedWorkStd1Table.length
        };

        //console.log(msg);

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data[0]);

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT_STU_JUDG_BY_IDV_WORK",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            work_id: stateSelectedPersonWorkId,
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    // //console.log(json);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("user_student_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                let array = [];
                                let offset = stateSelectedWorkStd1Table.length; // 3

                                for (let i = 0; i < data.length; i = i + offset) {
                                    let element = [];
                                    element.push(data[i].prefix); // 0
                                    element.push(data[i].first_name); // 1
                                    element.push(data[i].last_name); // 2
                                    element.push(data[i].id); // 3
                                    element.push(data[i].result); // 4
                                    element.push(data[i].datetime); // 5
                                    array.push(element);
                                }

                                for (let i = 0; i < array.length; i++) {
                                    for (let j = 1; j < offset; j++) {
                                        array[i].push(data[i * offset + j].id); // 6
                                        array[i].push(data[i * offset + j].result); // 7
                                        array[i].push(data[i * offset + j].datetime); // 8
                                    }
                                }

                                // for (let i = 0; i < array.length; i++) {
                                //     array[i].push(data[i * offset + 1].id); // 6
                                //     array[i].push(data[i * offset + 1].result); // 7
                                //     array[i].push(data[i * offset + 1].datetime); // 8
                                //     array[i].push(data[i * offset + 2].id); // 9
                                //     array[i].push(data[i * offset + 2].result); // 10
                                //     array[i].push(data[i * offset + 2].datetime); // 11
                                // }

                                setStateSelectedWorkStudentStd1Table(null);
                                setStateSelectedWorkStudentStd1Table(array);
                            } else {
                                setStateSelectedWorkStudentStd1Table(null);
                            }
                        });
                }
            });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleCheckClick(e) {
        let std1Table = stateSelectedWorkStudentStd1Table;

        // let index = std2Table.findIndex(function (object, i) {
        //     return object.id === e.target.getAttribute("id");
        // });

        //let id = e.target.getAttribute("id");
        let array_no = parseInt(e.target.getAttribute("array_no"));
        let index = parseInt(e.target.getAttribute("index"));

        // console.log(id);
        // console.log(array_no);
        // console.log(index);

        if (std1Table[array_no][index] === "0") {
            std1Table[array_no][index] = "1";
        } else {
            std1Table[array_no][index] = "0";
        }

        setStateSelectedWorkStudentStd1Table(null);
        setStateSelectedWorkStudentStd1Table(std1Table);
        //console.log(std1Table);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let selectedWorkStd1StudentTableHeaderData = null;
    let selectedWorkStd1StudentTableBodyData = null;

    if (stateSelectedWorkStudentStd1Table && stateSelectedWorkStd1Table) {
        selectedWorkStd1StudentTableHeaderData =
            <thead>
                <tr>
                    <th><div align="center" style={{ whiteSpace: "nowrap" }}>ที่</div></th>
                    <th><div align="center" style={{ whiteSpace: "nowrap" }}>ชื่อ</div></th>
                    <th><div align="center" style={{ whiteSpace: "nowrap" }}>นามสกุล</div></th>
                    {
                        stateSelectedWorkStd1Table.map(function (object, i) {
                            return <th key={i}><div align="center" style={{ whiteSpace: "nowrap" }}>{`มฐ.${i + 1}`}</div></th>
                            // (


                            //         {/* <th ><div align="center" style={{ whiteSpace: "nowrap" }}>{`มฐ.${i + 1}`}</div></th>
                            //         <th ><div align="center" style={{ whiteSpace: "nowrap" }}>{`มฐ.${i + 1}`}</div></th> */}
                            //     );
                        })
                    }
                </tr>
            </thead >;

        let table = [];

        for (let i = 0; i < stateSelectedWorkStudentStd1Table.length; i++) {
            let row = [];
            row.push(<div align="center" style={{ whiteSpace: "nowrap" }}>{i + 1}</div>);
            row.push(<div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "75px" }}>{stateSelectedWorkStudentStd1Table[i][1]}</div>);
            row.push(<div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "75px" }}>{stateSelectedWorkStudentStd1Table[i][2]}</div>);

            for (let j = 0; j < stateSelectedWorkStd1Table.length; j++) {
                row.push(<div align="center" style={{ whiteSpace: "nowrap" }}><Form.Check id={stateSelectedWorkStudentStd1Table[i][j * stateSelectedWorkStd1Table.length + 3]} array_no={i} index={j * stateSelectedWorkStd1Table.length + 4} type="checkbox" disabled={!stateCheckEnable} defaultChecked={stateSelectedWorkStudentStd1Table[i][j * stateSelectedWorkStd1Table.length + 4] === "1" ? true : false} onChange={handleCheckClick} /></div>);
            }

            table.push(row);
        }

        selectedWorkStd1StudentTableBodyData =
            <tbody>
                {
                    table.map(function (row, i) {
                        return (
                            <tr key={i}>
                                {row.map(function (columm, j) {
                                    return <td key={j}>{columm}</td>
                                })}
                            </tr>);
                    })
                }
            </tbody>;

        // selectedWorkStd1StudentTableBodyData =
        //     <tbody>
        //         {
        //             stateSelectedWorkStudentStd1Table.map(function (object, i) {
        //                 return (
        //                     <tr key={i}>
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}>{i + 1}</div></td>
        //                         <td><div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "75px" }}>{object[1]}</div></td>
        //                         <td><div align="left" style={{ whiteSpace: "nowrap", overflow: "hidden", textOverflow: "ellipsis", width: "60px" }}>{object[2]}</div></td>
        //                         {/* <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[3]}</div></td> */}
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}><Form.Check id={object[3]} array_no={i} index={4} type="checkbox" disabled={!stateCheckEnable} defaultChecked={object[4] === "1" ? true : false} onChange={handleCheckClick} /></div></td>
        //                         {/* <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[5]}</div></td>
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[6]}</div></td> */}
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}><Form.Check id={object[6]} array_no={i} index={7} type="checkbox" disabled={!stateCheckEnable} defaultChecked={object[7] === "1" ? true : false} onChange={handleCheckClick} /></div></td>
        //                         {/* <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[8]}</div></td>
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[9]}</div></td> */}
        //                         <td><div align="center" style={{ whiteSpace: "nowrap" }}><Form.Check id={object[9]} array_no={i} index={10} type="checkbox" disabled={!stateCheckEnable} defaultChecked={object[10] === "1" ? true : false} onChange={handleCheckClick} /></div></td>
        //                         {/* <td><div align="center" style={{ whiteSpace: "nowrap" }}>{object[11]}</div></td> */}
        //                     </tr>);
        //             })
        //         }
        //     </tbody>;
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <Container fluid>
            <Row>
                <Col align="left">
                    <br />
                    <Card bg="light" text="#000000">
                        <Card.Header style={{ fontColor: "#000000" }}>
                            <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <Row>
                                    <Col sm="auto" style={{ paddingRight: "0px" }}><b>มาตรฐานที่ 1 คุณภาพของผู้เรียน</b></Col>
                                    <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body>
                            <Dropdown hidden={statePersonWorkTable == null}>
                                <Dropdown.Toggle variant="dark">{stateSelectedPersonWorkLabel}</Dropdown.Toggle>
                                <Dropdown.Menu variant="dark">
                                    {personWorkDropdownData}
                                </Dropdown.Menu>
                            </Dropdown><br />
                            <Table bordered variant="dark" size="sm" hidden={statePersonWorkTable == null}>
                                <tbody>
                                    {stateSelectedWorkStudentStd1Table ? selectedWorkStd1Data : null}
                                    <tr>
                                        <td>วันเวลาที่ประเมินล่าสุด : {stateSelectedWorkStudentStd1Table ? stateSelectedWorkStudentStd1Table[0][5] : ""}</td>
                                    </tr>
                                </tbody>
                            </Table>
                            {
                                (stateSelectedWorkStudentStd1Table && statePersonWorkTable) ?
                                    <>
                                        <Button hidden={!stateEditButtonShow} variant="success" size="md" onClick={handleEditClick} text="#FFFFFF">แก้ไข</Button>&nbsp;
                                        <Button hidden={!stateConfirmButtonShow} variant="warning" size="md" onClick={handleConfirmClick} text="#FFFFFF">บันทึก</Button><br /><br />
                                    </>
                                    :
                                    null
                            }
                            <Table striped bordered size="sm" hidden={statePersonWorkTable == null}>
                                {selectedWorkStd1StudentTableHeaderData}
                                {selectedWorkStd1StudentTableBodyData}
                            </Table>
                        </Card.Body>
                    </Card><br />
                </Col>
            </Row>
        </Container>
    );
}

export default Std1Teacher;