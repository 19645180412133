import './App.css';
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Nav, Navbar, Button, Modal, FormControl, Alert, Table, InputGroup } from "react-bootstrap";
import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import Cookies from "js-cookie";
import { API } from "./API";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash} from '@fortawesome/free-solid-svg-icons';

import Home from "./components/Home";
import Std1Teacher from "./components/Std1Teacher";
import Std2Teacher from "./components/Std2Teacher";
import Std3Teacher from "./components/Std3Teacher";
import Std1Manager from "./components/Std1Manager";
import Std2Manager from "./components/Std2Manager";
import Std3Manager from "./components/Std3Manager";
import Admin from "./components/Admin";

const api = API();

function App() {
  let errorMsg = [];

  const reduxState = useSelector(state => state);
  const reduxDispatch = useDispatch();

  const [stateID, setStateID] = useState("");
  const [statePassword, setStatePassword] = useState("");
  const [statePasswordEye, setStatePasswordEye] = useState(false);
  const [stateLoginShow, setStateLoginShow] = useState(true);
  const [stateErrorShow, setStateErrorShow] = useState(false);
  const [stateErrorMsg, setStateErrorMsg] = useState("");

  const [stateSelectedPage, setStateSelectedPage] = useState("1");

  useEffect(() => {
    if (Cookies.get("user-id") !== undefined) {
      setStateLoginShow(false);

      let id = Cookies.get("user-id");
      let password = Cookies.get("user-password");

      const obj = {
        id: id,
        password: password
      }

      const json = JSON.stringify(obj);

      api.post("login.php", Buffer.from(json).toString("base64"))
        .then(res => {
          const data = JSON.parse(Buffer.from(res.data, "base64").toString());

          if (data[0] !== "fail") {
            Cookies.set("user-id", data[0].id, { expires: 1 });
            Cookies.set("user-password", data[0].password, { expires: 1 });

            {
              const reduxState = {
                userID: data[0].id,
                userPassword: data[0].password,
                userPrefix: data[0].prefix,
                userFirstName: data[0].first_name,
                userLastName: data[0].last_name,
                userEmail: data[0].email,
                userType: data[0].type,
                userState: data[0].state
              }

              reduxDispatch({ type: "UPDATE", reduxState });
            }
          } else {
            setStateLoginShow(true);
          }
        })
        .catch(err => {
        });
    }

    return () => {
    }
  }, []);

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleHomeClick(e) {
    e.preventDefault();

    setStateSelectedPage("1");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd1TeacherClick(e) {
    e.preventDefault();

    setStateSelectedPage("2");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd2TeacherClick(e) {
    e.preventDefault();

    setStateSelectedPage("3");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd3TeacherClick(e) {
    e.preventDefault();

    setStateSelectedPage("4");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd1ManagerClick(e) {
    e.preventDefault();

    setStateSelectedPage("5");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd2ManagerClick(e) {
    e.preventDefault();

    setStateSelectedPage("6");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleStd3ManagerClick(e) {
    e.preventDefault();

    setStateSelectedPage("7");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleAdminClick(e) {
    e.preventDefault();

    setStateSelectedPage("8");
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleLogoutClick(e) {
    e.preventDefault();

    Cookies.remove("user-id");
    Cookies.remove("user-password");

    window.location.reload(false);

    // api.post("logout.php", "")
    //   .then(res => {
    //     let data = JSON.parse(Buffer.from(res.data, "base64").toString());

    //     if (data[0] === "pass") {
    //       window.location.reload(false);
    //     }
    //   })
    //   .catch(err => {
    //   });
  }

  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////
  /////////////////////////////////////////////////////////////////////////////////////////////////////////////

  function handleIDChange(e) {
    const re = /^[0-9\b]+$/;

    if (e.target.value === "" || re.test(e.target.value)) {
      setStateID(e.target.value);
    }
  }

  function handlePasswordChange(e) {
    setStatePassword(e.target.value);
  }

  function handleLogin() {
    if (stateID.length === 13 && statePassword.length >= 8) {
      let md5 = require("md5");
      let id = stateID;
      let password = md5(statePassword);

      const obj = {
        id: id,
        password: password
      }

      const json = JSON.stringify(obj);

      api.post("login.php", Buffer.from(json).toString("base64"))
        .then(res => {
          const data = JSON.parse(Buffer.from(res.data, "base64").toString());

          if (data[0] !== "fail") {
            Cookies.set("user-id", data[0].id, { expires: 1 });
            Cookies.set("user-password", data[0].password, { expires: 1 });

            {
              const reduxState = {
                userID: data[0].id,
                userPassword: data[0].password,
                userPrefix: data[0].prefix,
                userFirstName: data[0].first_name,
                userLastName: data[0].last_name,
                userEmail: data[0].email,
                userType: data[0].type,
                userState: data[0].state,
              }

              reduxDispatch({ type: "UPDATE", reduxState });
            }

            setStateLoginShow(false);
            setStateErrorShow(false);
          } else {
            errorMsg = [];
            setStateErrorShow(true);

            errorMsg.push("ไม่พบเลขประจำตัวประชาชนหรือรหัสไม่ถูกต้อง");

            setStateErrorMsg(errorMsg.map(function (object, index) {
              return <div key={index}>{`- `}{object}</div>;
            }));
          }
        })
        .catch(err => {
          errorMsg = [];
          setStateErrorShow(true);

          errorMsg.push("เครือข่ายผิดพลาด");

          setStateErrorMsg(errorMsg.map(function (object, index) {
            return <div key={index}>{`- `}{object}</div>;
          }));
        });
    } else {
      errorMsg = [];
      setStateErrorShow(true);

      if (stateID.length !== 13) {
        errorMsg.push("เลขประจำตัวประชาชนจะต้องมี 13 หลัก");
      }

      if (statePassword.length < 8) {
        errorMsg.push("รหัสผ่านจะต้องมีอย่างน้อย 8 ตัว");
      }

      setStateErrorMsg(errorMsg.map(function (object, index) {
        return <div key={index}>{`- `}{object}</div>;
      }));
    }
  }

  let navBrand = <Navbar.Brand><img src="ppklogo.ico" style={{ height: "36px" }} /> PPKQAS</Navbar.Brand>;
  let menu = null;
  let page = null;

  if (reduxState.userType === "แอดมิน") {
    navBrand = <Navbar.Brand><img src="ppklogo.ico" style={{ height: "36px" }} /> PPKQAS (Admin)</Navbar.Brand>;

    menu =
      <Nav className="me-auto">
        <Nav.Link onClick={handleHomeClick} href="#a1">หน้าแรก</Nav.Link>
        <Nav.Link onClick={handleStd1TeacherClick} href="#a2">มาตรฐานที่ 1</Nav.Link>
        <Nav.Link onClick={handleStd2TeacherClick} href="#a3">มาตรฐานที่ 2</Nav.Link>
        <Nav.Link onClick={handleStd3TeacherClick} href="#a4">มาตรฐานที่ 3</Nav.Link>
        <Nav.Link onClick={handleStd1ManagerClick} href="#a5">มาตรฐานที่ 1 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd2ManagerClick} href="#a6">มาตรฐานที่ 2 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd3ManagerClick} href="#a7">มาตรฐานที่ 3 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleAdminClick} href="#a8">ผู้ดูแลระบบ</Nav.Link>
        <Nav.Link onClick={handleLogoutClick} href="#a9">ออกจากระบบ</Nav.Link>
      </Nav >;
  } else if (reduxState.userType === "ครู") {
    navBrand = <Navbar.Brand><img src="ppklogo.ico" style={{ height: "36px" }} /> PPKQAS (Teacher)</Navbar.Brand>;

    menu =
      <Nav className="me-auto">
        <Nav.Link onClick={handleHomeClick} href="#a1">หน้าแรก</Nav.Link>
        <Nav.Link onClick={handleStd1TeacherClick} href="#a2">มาตรฐานที่ 1</Nav.Link>
        <Nav.Link onClick={handleStd2TeacherClick} href="#a3">มาตรฐานที่ 2</Nav.Link>
        <Nav.Link onClick={handleStd3TeacherClick} href="#a4">มาตรฐานที่ 3</Nav.Link>
        <Nav.Link onClick={handleLogoutClick} href="#a6">ออกจากระบบ</Nav.Link>
      </Nav >;
  } else if (reduxState.userType === "ผู้บริหาร") {
    navBrand = <Navbar.Brand><img src="ppklogo.ico" style={{ height: "36px" }} /> PPKQAS (Manager)</Navbar.Brand>;

    menu =
      <Nav className="me-auto">
        <Nav.Link onClick={handleHomeClick} href="#a1">หน้าแรก</Nav.Link>
        <Nav.Link onClick={handleStd1ManagerClick} href="#a2">มาตรฐานที่ 1 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd2ManagerClick} href="#a3">มาตรฐานที่ 2 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd3ManagerClick} href="#a4">มาตรฐานที่ 3 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleLogoutClick} href="#a6">ออกจากระบบ</Nav.Link>
      </Nav >;
  } else if (reduxState.userType === "ผู้สังเกต") {
    navBrand = <Navbar.Brand><img src="ppklogo.ico" style={{ height: "36px" }} /> PPKQAS (Guest)</Navbar.Brand>;

    menu =
      <Nav className="me-auto">
        <Nav.Link onClick={handleHomeClick} href="#a1">หน้าแรก</Nav.Link>
        <Nav.Link onClick={handleStd1ManagerClick} href="#a2">มาตรฐานที่ 1 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd2ManagerClick} href="#a3">มาตรฐานที่ 2 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleStd3ManagerClick} href="#a4">มาตรฐานที่ 3 (ภาพรวม)</Nav.Link>
        <Nav.Link onClick={handleLogoutClick} href="#a6">ออกจากระบบ</Nav.Link>
      </Nav >;
  }

  if (stateSelectedPage === "1") {
    page = <Home />;
  } else if (stateSelectedPage === "2") {
    page = <Std1Teacher />;
  } else if (stateSelectedPage === "3") {
    page = <Std2Teacher />;
  } else if (stateSelectedPage === "4") {
    page = <Std3Teacher />;
  } else if (stateSelectedPage === "5") {
    page = <Std1Manager />;
  } else if (stateSelectedPage === "6") {
    page = <Std2Manager />;
  } else if (stateSelectedPage === "7") {
    page = <Std3Manager />;
  } else if (stateSelectedPage === "8") {
    page = <Admin />;
  }

  return (
    <div align="center">
      <div style={{ fontFamily: "Sarabun", maxWidth: "1024px" }}>
        <Navbar collapseOnSelect expand={false} bg="dark" variant="dark" sticky="top">
          <Container fluid>
            {navBrand}
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav" >
              {menu}
            </Navbar.Collapse>
          </Container>
        </Navbar>
        <div align="left">
          {page}
        </div>
        <Modal show={stateLoginShow} backdrop="static" keyboard={false} style={{ fontFamily: "Sarabun" }} centered
          size="md"
        >
          <Modal.Header>
            <Modal.Title>กรุณากรอกเลขประจำตัวประชาชนและรหัสผ่านเพื่อเข้าสู่ระบบ</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Table borderless={true} size="sm" responsive="sm">
              <tbody>
                <tr>
                  <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <Alert variant="danger" show={stateErrorShow}>{stateErrorMsg}</Alert>
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <FormControl value={stateID} onChange={handleIDChange} placeholder="เลขประจำตัวประชาชน" />
                  </td>
                </tr>
                <tr>
                  <td style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                    <InputGroup>
                      <FormControl type={statePasswordEye ? `input` : `password`} value={statePassword} onChange={handlePasswordChange} placeholder="รหัสผ่าน" />
                      <Button variant="light" onClick={() => setStatePasswordEye(prev => ~prev)}>
                        {statePasswordEye ? <FontAwesomeIcon icon={faEyeSlash}></FontAwesomeIcon> : <FontAwesomeIcon icon={faEye}></FontAwesomeIcon>}
                      </Button>
                    </InputGroup>
                  </td>
                </tr>
              </tbody>
            </Table>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="success" onClick={handleLogin}>
              เข้าสู่ระบบ
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    </div >
  );
}

export default App;
