import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "bootstrap/dist/css/bootstrap.min.css";
import { Container, Row, Col, Card, Table, Form, Button } from "react-bootstrap";
import { API } from "../API";

const api = API();

function Std2Teacher() {
    const reduxState = useSelector(state => state);
    const reduxDispatch = useDispatch();

    const [stateSelectedEduYear, setStateSelectedEduYear] = useState(null);
    const [stateSelectedEduYearId, setStateSelectedEduYearId] = useState(null);
    const [stateSelectedEduTerm, setStateSelectedEduTerm] = useState(null);
    const [stateStd2Table, setStateStd2Table] = useState(null);

    const [stateCheckBoxDisable, setStateCheckBoxDisable] = useState(true);
    const [stateEditButtonShow, setStateEditButtonShow] = useState(false);
    const [stateConfirmButtonShow, setStateConfirmButtonShow] = useState(false);

    useEffect(() => {
        const msg = {
            command: "SELECT",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: ""
        };

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("user_selected_edu_year_and_term.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data);

                if (data[0] !== "fail") {
                    setStateSelectedEduYearId(data[0].edu_years_id);
                    setStateSelectedEduYear(data[0].edu_years_name);
                    setStateSelectedEduTerm(data[0].edu_term);

                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: data[0].edu_years_id,
                            edu_term: data[0].edu_term
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("user_management_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                //console.log(data);

                                setStateStd2Table(data);
                                setStateEditButtonShow(true);
                            } else {
                                setStateStd2Table(null);
                            }
                        });
                } else {
                    setStateSelectedEduYear(null);
                    setStateSelectedEduYearId(null);
                    setStateSelectedEduTerm(null);
                }
            });

        return () => {
        }
    }, []);

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    function handleEditClick(e) {
        e.preventDefault();

        setStateCheckBoxDisable(false);
        setStateEditButtonShow(false);
        setStateConfirmButtonShow(true);
    }

    function handleConfirmClick(e) {
        e.preventDefault();

        setStateStd2Table(null);
        setStateConfirmButtonShow(false);

        const msg = {
            command: "UPDATE",
            id: reduxState.userID,
            password: reduxState.userPassword,
            data: stateStd2Table
        };

        //console.log(msg);

        const json = JSON.stringify(msg);

        const options = {
            header: {
                "content-type": "text/plain"
            }
        };

        api.post("user_management_judgment.php", Buffer.from(json).toString("base64"), options)
            .then(res => {
                let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                //console.log(data[0]);

                if (data[0] === "pass") {
                    const msg = {
                        command: "SELECT",
                        id: reduxState.userID,
                        password: reduxState.userPassword,
                        data: {
                            edu_year_id: stateSelectedEduYearId,
                            edu_term: stateSelectedEduTerm
                        }
                    };

                    const json = JSON.stringify(msg);

                    const options = {
                        header: {
                            "content-type": "text/plain"
                        }
                    };

                    api.post("user_management_judgment.php", Buffer.from(json).toString("base64"), options)
                        .then(res => {
                            let data = JSON.parse(Buffer.from(res.data, "base64").toString());

                            //console.log(data);

                            if (data[0] !== "fail") {
                                for (let i = 0; i < data.length; i++) {
                                    data[i] = data[i][0];
                                }

                                //console.log(data);

                                setStateStd2Table(data);

                                setStateCheckBoxDisable(true);
                                setStateEditButtonShow(true);
                                setStateConfirmButtonShow(false);
                            } else {
                                setStateStd2Table(null);
                            }
                        });
                }
            });
    }

    function handleCheckClick(e) {
        let std2Table = stateStd2Table;

        let index = std2Table.findIndex(function (object, i) {
            return object.id === e.target.getAttribute("id");
        });

        //console.log(index);

        if (std2Table[index].result === "0") {
            std2Table[index].result = "1";
        } else {
            std2Table[index].result = "0";
        }

        setStateStd2Table(std2Table);
        //console.log(stateStd3Table);
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    let std2TableData = null;

    if (stateStd2Table) {
        std2TableData = stateStd2Table.map(function (object, index) {
            return <tr key={index}>
                <td style={{ textAlign: "center", verticalAlign: "top" }}>{index + 1}</td>
                <td style={{ textAlign: "left", verticalAlign: "top" }}>{object.name}<br />วันเวลาที่ประเมินล่าสุด : {object.datetime}</td>
                <td style={{ textAlign: "center", verticalAlign: "middle" }}><Form.Check id={object.id} type="checkbox" disabled={stateCheckBoxDisable} defaultChecked={object.result === "1" ? true : false} onChange={handleCheckClick} /></td>
            </tr>
        });
    }

    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////
    //////////////////////////////////////////////////////////////////////////////////////////////////////////

    return (
        <Container fluid>
            <Row>
                <Col align="left">
                    <br />
                    <Card bg="light" text="#000000">
                        <Card.Header style={{ fontColor: "#000000" }}>
                            <Container fluid style={{ paddingLeft: "0px", paddingRight: "0px" }}>
                                <Row>
                                    <Col sm="auto" style={{ paddingRight: "0px" }}><b>มาตรฐานที่ 2 กระบวนการบริหารและการจัดการ</b></Col>
                                    <Col style={{ borderLeft: "0px", paddingRight: "0px" }}><b>ปีการศึกษา {stateSelectedEduYear} ภาคเรียนที่ {stateSelectedEduTerm}</b></Col>
                                </Row>
                            </Container>
                        </Card.Header>
                        <Card.Body>
                            <Button hidden={!stateEditButtonShow} variant="success" size="md" onClick={handleEditClick} text="#FFFFFF">แก้ไข</Button>
                            <Button hidden={!stateConfirmButtonShow} variant="warning" size="md" onClick={handleConfirmClick} text="#FFFFFF">บันทึก</Button>
                            <br />
                            <br />
                            <Table striped bordered size="sm">
                                <thead>
                                    <tr>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>ที่</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>มาตรฐานสถานศึกษา</th>
                                        <th style={{ textAlign: "center", verticalAlign: "middle" }}>ผลการประเมิน</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {std2TableData}
                                </tbody>
                            </Table>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
    );
}

export default Std2Teacher;